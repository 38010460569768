import { Component, OnInit, ViewChild } from '@angular/core';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../core/manning-agency-admin.service';
import { ManningAgencyService } from '../../core/manning-agency.service';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UnderconstructionModalComponent } from '../../core/underconstruction-modal/underconstruction-modal.component';
import { DeleteModalComponent } from '../../core/delete-modal/delete-modal.component';
import { InviteSeafarerModalComponent } from '../../core/invite-seafarer-modal/invite-seafarer-modal.component';

// import * as positions from '../../core/constants/positions.json';
import { SeafarerAdminService } from '../../core/seafarer-admin.service';

import * as _ from 'lodash';
import { ChatService } from '../../core/chat.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manning-agency-app-find-seaman',
  templateUrl: './manning-agency-app-find-seaman.component.html',
  styleUrls: ['./manning-agency-app-find-seaman.component.css']
})
export class ManningAgencyAppFindSeamanComponent implements OnInit {
  @ViewChild('inviteModal') inviteModal: ModalDirective;
  bsModalRef: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false
  };
  minDate = new Date();
  maId;
  token;
  positionToSearch;
  ranks = [];
  seamen = [];
  positions = [];
  filteredRanks = [];
  selectedPosition;

  constructor(
    private headerService: ManningAgencyHeaderService,
    private cookieService: CookieService,
    private maService: ManningAgencyService,
    private seafarerAdminService: SeafarerAdminService,
    private manningAgencyService: ManningAgencyAdminService,
    private bsModalService: BsModalService,
    private chatService: ChatService,
    private router: Router
  ) { }

  ngOnInit() {
    this.headerService.changeHeader('find-seaman');
    this.maId = this.cookieService.get('roleId');
    this.token = this.cookieService.get('token');
    this.seafarerAdminService
      .getSeafarerRankCount(this.token)
        .subscribe(response => {
          if (response && response.success) {
            this.ranks = response.data.items;
            this.filteredRanks = _.cloneDeep(this.ranks);
            this.positions = this.ranks.map(rank => rank.lastRankOn);

            this.selectedPosition = _.get(this.ranks, '0.lastRankOn', undefined);
            this.getSeamanByRank(this.selectedPosition);
          }
        });
  }

  getCount(event) {
    this.positionToSearch = event.item;
    this.seamen = [];

    this.filteredRanks = _.filter(this.ranks, r => r.lastRankOn.match(new RegExp(this.positionToSearch, 'ig')));

    if (!this.positionToSearch.length) {
      this.positionToSearch = _.get(this.ranks, '0.lastRankOn', undefined);
    }

    this.getSeamanByRank(this.positionToSearch);
  }

  getSeamanByRank(position) {
    this.selectedPosition = position;

    this.seafarerAdminService
      .getSeafarerRank(this.token, position)
      .subscribe(res => {
        if (res && res.success) {
          this.seamen = res.data.items;
        }
      });
  }

  messageSeaman(userId) {
    const thread = {
      userId
    };

    this.chatService.createMessage(this.token, thread)
        .subscribe(res => {
          if (res && res.success) {
            this.router.navigate(['/manning-agency/messages'], { queryParams: { userId } });
          }
        });
  }


}
