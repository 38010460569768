import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../core/subscription.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-manning-agency-subscription-modal',
  templateUrl: './manning-agency-subscription-modal.component.html',
  styleUrls: ['./manning-agency-subscription-modal.component.css']
})
export class ManningAgencySubscriptionModalComponent implements OnInit {
  subscriptionLink: string;
  gettingSubscription = false;
  type;
  subscriptionLoaded = false;
  token;
  currentPlan;
  subscriptions = {
    basic: {
      title: 'Basic Annual',
      description: 'Php 1,995/ month billed annually.'
    },
    basic_3_months: {
      title: 'Basic 3 months',
      description: 'Php 2,399 / month for 3 months.'
    },
    basic_6_months: {
      title: 'Basic 6 months',
      description: 'Php 2,199 / month for 6 months.'
    },
    premium: {
      title: 'Premium Annual',
      description: 'Php 3,499/ month billed annually.'
    },
    premium_3_months: {
      title: 'Basic 3 months',
      description: 'Php 4,199 / month for 3 months.'
    },
    premium_6_months: {
      title: 'Basic 6 months',
      description: 'Php 3,999 / month for 6 months.'
    }
  }

  constructor(
    private cookieService: CookieService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
  }

  getSubscriptionToken(plan) {
    this.gettingSubscription = true;
    this.currentPlan = plan;
    this.subscriptionService.getSubscriptionLink(this.token, plan)
      .subscribe(res => {
        this.subscriptionLoaded = true;
        this.subscriptionLink = res.url;
      });
  }

}
