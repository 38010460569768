import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SeafarerService } from '../../core/seafarer.service';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'app-seafarer-delete-page',
  templateUrl: './seafarer-delete-page.component.html',
  styleUrls: ['./seafarer-delete-page.component.css']
})
export class SeafarerDeletePageComponent implements OnInit {
  user = {
    email: '',
    password: ''
  };

  constructor(
    private authService: AuthService,
    private seafarerService: SeafarerService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
  }

  delete() {
    this.authService.login('seafarer', this.user)
      .subscribe(response => {
        if (response && response.success) {
          this.seafarerService.delete(response.data.token, response.data.seafarerId)
            .subscribe(res => {
              if (res && res.success) {
                this.toastr.success('Seafarer deleted successfully');
              }
            });
        }
      })
  }

}
