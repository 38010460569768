import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MotherService } from './mother.service';

@Injectable()
export class ChatService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  getThreads(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/threads?size=1000`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetThreads`))
      );
  }

  getMessages(token, threadId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/thread/${threadId}/messages`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetMessages`))
      );
  }

  createMessage(token, thread) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/thread`;
    return this.http.post<any>(url, thread, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('CreateMessage'))
      );
  }

  createMessageWithAttachment(token, thread) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/thread`;
    const requestForm = new FormData();
    requestForm.append('message', 'message with attachment');
    requestForm.append('userId', thread.userId);
    requestForm.append('threadId', thread.threadId);
    requestForm.append('chat', thread.file, thread.file.name);

    return this.http.post<any>(url, requestForm, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('CreateMessageWithAttachment'))
      );
  }

  markAsRead(token, threadId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/thread/${threadId}`;
    return this.http.put<any>(url, {}, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('MarkAsRead'))
      );
  }

  getUnreadMessages(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/thread/unread_messages`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetUnreadMessages`))
      );
  }

}
