import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.css']
})
export class ContentHeaderComponent implements OnInit {
  @Input() header;
  @Input() note;
  @Input() total;
  @Input() year;
  @Input() course;

  constructor() { }

  ngOnInit() {
  }

}
