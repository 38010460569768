
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class SessionService implements CanActivate {

  constructor(
    private router: Router,
    private cookieService: CookieService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const roles = route.data['roles'] as Array<string>;

    if (this.cookieService.check('token')) {
      const currentRole = this.cookieService.get('role');

      if (!roles.includes(currentRole)) {
        this.router.navigate([`/${currentRole}`]);
        return false;
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate([`/${roles[0]}/login`], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
