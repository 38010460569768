import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../../core/manning-agency-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UnderconstructionModalComponent } from '../../../core/underconstruction-modal/underconstruction-modal.component';

@Component({
  selector: 'app-manning-agency-profile',
  templateUrl: './manning-agency-profile.component.html',
  styleUrls: ['./manning-agency-profile.component.css']
})
export class ManningAgencyProfileComponent implements OnInit {
  token;
  maId;
  profile = {
    manning_agencyId: 0,
    name: '',
    email: '',
    contactPerson: '[{}]',
    operatingDays: '',
    companyLogo: '',
    about: '',
    address: '',
    operatingHours: '',
    telNumber: '',
    mobileNumber: '',
    poeaLicenseNumber: '',
  };
  rejectedReason = '';

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyAdminService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.maId = this.route.snapshot.paramMap.get('id');
    this.manningAgencyService.getProfile(this.token, this.maId)
      .subscribe(res => {
        if (res && res.success) {
          if (!res.data.isAccepted) {
            return this.router.navigate([`/admin/manning-agency/pending/${this.maId}`]);
          } else if (res.data.isRejected) {
            return this.router.navigate([`/admin/manning-agency`]);
          }
          this.profile = res.data;
        }
      });
  }

  message() {
    this.bsModalService.show(UnderconstructionModalComponent);
  }

  ban() {
    this.bsModalService.show(UnderconstructionModalComponent);
  }

}
