import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seafarers-messages',
  templateUrl: './seafarers-messages.component.html',
  styleUrls: ['./seafarers-messages.component.css']
})
export class SeafarersMessagesComponent implements OnInit {
  user;
  token;
  threadId;
  openedThread = {
    threadId: 0,
    userId: 0
  };
  readThreads = [];
  unreadThreads = [];
  unfilteredReadThreads = [];
  unfilteredUnreadThreads = [];
  messages = [];
  myUserId;
  openedChat = false;
  textareaRows = 2;
  message = '';
  search = '';
  files: any = [];
  galleryFiles: any = [];
  userId;

  constructor() { }

  ngOnInit() {
  }

}
