import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../../core/subscription.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-manning-agency-thank-you',
  templateUrl: './manning-agency-thank-you.component.html',
  styleUrls: ['./manning-agency-thank-you.component.css']
})

export class ManningAgencyThankYouComponent implements OnInit {
  token;
  subscriptionId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.subscriptionId = this.route.snapshot.paramMap.get('id');
    const paymentToken = this.route.snapshot.queryParams['token'] || null;

    this.subscriptionService.subscribe(this.token, paymentToken, this.subscriptionId)
      .subscribe(() => {
        this.router.navigate(['/manning-agency']);
      });
  }

}
