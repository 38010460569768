import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyService } from '../../core/manning-agency.service';
import { Router } from '@angular/router';
import { ManningAgencyBaseComponent } from '../../component/manning-agency-base/manning-agency-base.component';

@Component({
  selector: 'app-manning-agency-registration',
  templateUrl: './manning-agency-registration.component.html',
  styleUrls: ['./manning-agency-registration.component.css']
})
export class ManningAgencyRegistrationComponent extends ManningAgencyBaseComponent implements OnInit {
  token;
  pageNum = 0;
  user = {
    name: '',
    address: '',
    email: '',
    telNum: [
      {
        value: ''
      }
    ],
    telNumber: '',
    mobNum: [
      {
        value: ''
      }
    ],
    mobileNumber: '',
    cp: [
      {
        name: '',
        rank: '',
        phone: ''
      }
    ],
    operatingHours: '',
    fromTime: '',
    toTime: '',
    optDays: [false, false, false, false, false, false, false],
    lat: 14.5995,
    lng: 120.9842,
    poeaLicenseNumber: '',
    password: '',
    confirmPassword: '',
    operatingDays: '',
    contactPerson: '',
    isActivated: 1
  };
  companyLogo;
  logoSrc;
  days = [
    'Sun', 'Mon', 'Tue',
    'Wed', 'Thu', 'Fri', 'Sat'
  ];
  locationChosen = false;
  agree = false;
  clickedRegister = false;
  isRegisteredByAdmin = false;

  constructor(
    public location: Location,
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyService
  ) { super(); }

  ngOnInit() {
    this.isRegisteredByAdmin = this.cookieService.get('role') === 'admin';
  }

  addTelNum() {
    this.user.telNum.push({value: ''});
  }

  addMobNum() {
    this.user.mobNum.push({value: ''});
  }

  addContact() {
    this.user.cp.push({
      name: '',
      rank: '',
      phone: ''
    });
  }

  readyFiles(event) {
    const files: FileList = event.target.files;
    const file = files[0];
    this.companyLogo = file;
    const reader = new FileReader();
    reader.onload = (e) => {
      this.logoSrc = { name: file.name, imgSrc: reader.result };
    };
    reader.readAsDataURL(file);
  }

  removeImage() {
    this.logoSrc = void 0;
    this.companyLogo = void 0;
  }

  showDays() {
    const days = this.user.optDays
      .map((day, i) => day && this.days[i])
      .filter(d => d);
    return days.join(', ');
  }

  updateLocation(event) {
    this.user.lat = event.coords.lat;
    this.user.lng = event.coords.lng;
    this.locationChosen = true;
  }

  isValid() {
    return (
      this.user.name.length &&
      this.user.address.length &&
      this.locationChosen &&
      this.showDays().length &&
      this.user.fromTime &&
      this.user.toTime &&
      this.user.telNum.some(telNum => !!telNum.value.length) &&
      this.user.mobNum.some(mobNum => !!mobNum.value.length) &&
      this.user.poeaLicenseNumber
    );
  }

  isValidContact() {
    return this.user.cp
      .some(person => !!(person.name.length && person.phone.length && person.rank.length));
  }

  isValidToReg() {
    return (
      this.agree &&
      this.user.email &&
      (
        // We generate password when isRegisteredByAdmin is true
        this.isRegisteredByAdmin || (
          this.user.password.length &&
          this.user.confirmPassword.length &&
          this.user.password === this.user.confirmPassword
        )
      ) &&
      this.companyLogo
    );
  }

  register() {
    this.clickedRegister = true;
    this.user.operatingDays = this.user.optDays
      .map((day, i) => day && i)
      .filter(d => d)
      .join('');
    this.user.operatingHours = `${this.user.fromTime} - ${this.user.toTime}`;
    const mobileNum = this.user.mobNum
      .filter(mobNum => !!mobNum.value.length)
      .map(mobNum => `"${mobNum.value}"`)
      .join(',');
    this.user.mobileNumber = `[${mobileNum}]`;
    const teleNum = this.user.telNum
      .filter(telNum => !!telNum.value.length)
      .map(telNum => `"${telNum.value}"`)
      .join(',');
    this.user.telNumber = `[${teleNum}]`;
    this.user.contactPerson = JSON.stringify(this.user.cp);

    if (this.isRegisteredByAdmin) {
      this.user.isActivated = 0;
      this.user.password = this.generatePassword(this.user);
    }

    this.manningAgencyService.createMA(this.user)
      .subscribe(response => {
        if (response && response.success) {
          this.manningAgencyService.uploadImage(response.data.id, this.companyLogo)
            .subscribe(res => this.pageNum++);
        }
      });
  }

  generatePassword(manningAgency) {
    return super.generatePassword(manningAgency);
  }

  reload() {
    window.location.reload();
  }

}
