import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../../core/chat.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-manning-agency-messages',
  templateUrl: './manning-agency-messages.component.html',
  styleUrls: ['./manning-agency-messages.component.css']
})
export class ManningAgencyMessagesComponent implements OnInit {
  private token;
  messages = [];

  constructor(
    private chatService: ChatService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');

    this.chatService.getThreads(this.token)
      .subscribe(res => {
        if (res && res.success) {
          this.messages = res.data.items;
        }
      });
  }

}
