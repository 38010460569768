import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ManningAgencyHeaderService {
  private headerSource = new BehaviorSubject('new-post');
  activeHeader = this.headerSource.asObservable();

  constructor() { }

  changeHeader(header: string) {
    this.headerSource.next(header);
  }

}
