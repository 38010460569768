import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MotherService } from './mother.service';

@Injectable()
export class SeafarerViewAccessService extends MotherService  {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  getAccess(token, seafarerId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/seafarer_view_access/${seafarerId}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetAccess`))
      );
  }

  requestAccess(token, seafarerId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/seafarer_view_access/${seafarerId}`;

    return this.http.post<any>(url, {}, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`RequestAccess`))
      );
  }

}
