import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../core/manning-agency-admin.service';
import { ManningAgencyBaseComponent } from '../manning-agency-base/manning-agency-base.component';

@Component({
  selector: 'app-admin-manning-agency',
  templateUrl: './admin-manning-agency.component.html',
  styleUrls: ['./admin-manning-agency.component.css']
})
export class AdminManningAgencyComponent extends ManningAgencyBaseComponent implements OnInit {
  @ViewChild('emailSentModal') emailSentModal: ModalDirective;
  @ViewChild('editManningAgencyModal') editManningAgencyModal: ModalDirective;
  @ViewChild('deleteManningAgencyModal') deleteManningAgencyModal: ModalDirective;
  token;

  manningAgency = {
    manning_agencyId: 0,
    mobileNumber: [],
    temp_password: ''
  };

  properties = [];

  message = '';
  messageDefault = 'Emails have been all re-sent';

  constructor(
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyAdminService
  ) { super(); }

  ngOnInit() {
    this.token = this.cookieService.get('token');
  }

  sendEmail(manningAgency) {
    this.manningAgencyService.sendWelcomeEmail(this.token, manningAgency.manning_agencyId)
      .subscribe(res => {
        if (res && res.success) {
          this.message = `Email has been sent to ${manningAgency.name}`;
          this.emailSentModal.show();
        }
      });
  }

  sendEmailToAll() {
    this.manningAgencyService.sendWelcomeEmail(this.token)
      .subscribe(res => {
        if (res && res.success) {
          this.message = this.messageDefault;
          this.emailSentModal.show();
        }
      });
  }

  editManningAgency(manningAgency, properties = []) {
    this.manningAgency = manningAgency;
    this.properties = properties;
    this.editManningAgencyModal.show();
  }

  onSubmitEdit(form) {
    const manningAgency = _.clone(form.value);
    manningAgency.password = this.generatePassword(this.manningAgency);
    manningAgency.mobileNumber = this.manningAgency.mobileNumber;

    if (manningAgency.mobileNumber) {
      manningAgency.mobileNumber = JSON.stringify(manningAgency.mobileNumber);
    }

    this.manningAgencyService.update(this.token, this.manningAgency.manning_agencyId, manningAgency)
      .subscribe(res => {
        if (res && res.success) {
          this.manningAgency.temp_password = manningAgency.password;
          this.editManningAgencyModal.hide();
        }
      });
  }

  showDeleteManningAgencyModal(manningAgency) {
    this.manningAgency = manningAgency;

    this.deleteManningAgencyModal.show();
  }

  confirmDelete() {
    this.manningAgencyService.delete(this.token, this.manningAgency.manning_agencyId)
      .subscribe(res => {
        if (res && res.success) {
          window.location.reload();
        }
      });
  }

  isArray(obj : any) {
    return Array.isArray(obj);
  }

  addRow(array) {
    console.log(array);
    array.push('');
  }

}
