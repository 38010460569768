import { Component, OnInit, ViewChild } from '@angular/core';
import { SeafarerAdminService } from '../../../core/seafarer-admin.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-seafarers-online',
  templateUrl: './seafarers-online.component.html',
  styleUrls: ['./seafarers-online.component.css']
})
export class SeafarersOnlineComponent implements OnInit {
  token;
  format = 'YEARLY';
  seafarers = [];
  total = 0;
  pageNum = 1;
  pagination = [];
  top_ranks = {
    management_level: {
      label: 'Management Level Officers',
      data: [],
      columnNames: ['Management Level Officers', 'Total'],
    },
    operational_level: {
      label: 'Operational Level Officers',
      data: [],
      columnNames: ['Operational Level Officers', 'Total'],
    },
    support_level: {
      label: 'Support Level Crew',
      data: [],
      columnNames: ['Support Level Crew', 'Total'],
    },
    others: {
      label: 'Others',
      data: [],
      columnNames: ['Others', 'Total'],
    }
  };
  data = {
    seafarer_locations: [],
    top_ranks: {},
    online_seafarer_count: 0,
    seafarer_total_count: 0,
    online_history: []
  };
  ranks = Object.keys(this.top_ranks);
  option = {
    pieHole: 0.4,
    backgroundColor: '#ecf0f5',
    resize: true,
    chartArea: {
      width:'100%',
      height:'100%'
    },
    legend: {
      position: 'none'
    },
    slices: [
      {
        color: '#4695D9'
      },
      {
        color: '#E6EAEE'
      }
    ]
  };
  donutChart: morris.DonutChart;
  initGraph = false;

  constructor(
    private cookieService: CookieService,
    private seafarerService: SeafarerAdminService
  ) { }

  ngOnInit() {
    this.initData();
    console.log(this.top_ranks);
  }

  initData() {
    this.token = this.cookieService.get('token');
    return this.seafarerService.getOnlinePageData(this.token, this.format)
      .subscribe(response => {
        if (response && response.success) {
          this.data = response.data;
        }

        if (!this.initGraph) {
          this.initLine();
            // We want to load the element first before we run this
          setTimeout(this.initDonut.bind(this), 0);
        }
      });
  }

  updateFormat (format) {
    this.format = format;
    this.seafarerService.getOnlinePageData(this.token, this.format)
      .subscribe(response => {
        if (response && response.success) {
          this.data = response.data;
        }
      });
  }

  initLine() {
    Morris.Line({
      element: 'online-history-chart',
      data: this.data.online_history,
      xkey: 'date',
      ykeys: ['count'],
      labels: ['Online Seafarer']
    });
  }

  initDonut () {
    this.ranks.forEach((rank) => {
      this.top_ranks[rank].data = [
        [
          this.top_ranks[rank].label, this.data.top_ranks[rank] || 0
        ],
        [
          `Non ${this.top_ranks[rank].label}`, this.data.seafarer_total_count || 0
        ]
      ];
    });
  }
}
