import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MotherService } from './mother.service';

@Injectable()
export class SubscriptionService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  subscribe(token, paymentToken, subscriptionId) {
    const url = `${this.apiUrl}/subscribe`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const reqBody = {
      paymentToken,
      subscriptionId
    };
    return this.http.post<any>(url, reqBody, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('subscribe'))
      );
  }

  getSubscriptionLink(token, plan) {
    const url = `${this.apiUrl}/subscription?subscriptionPlan=${plan}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };

    return this.http.get<any>(url, httpOptions);
  }

  getSubscription(token, userId) {
    const url = `${this.apiUrl}/subscription/${userId}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('getSubscription'))
      );
  }

}
