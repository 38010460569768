import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MotherService } from './mother.service';

@Injectable()
export class SchoolAdminService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  getSchools(token, status, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/school/${status}?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`getSchools`))
      );
  }

  getDashboard(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/school/admin-dashboard`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetDashboard`))
      );
  }

  update(token, schoolId, data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/school/${schoolId}`;

    return this.http.put<any>(url, data, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Update School`))
      );
  }

  delete(token, schoolId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/school/${schoolId}`;

    return this.http.delete<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Delete School`))
      );
  }

  updateStatus(token, schoolId, status, data = {}) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/school/${schoolId}/${status}`;

    return this.http.put<any>(url, data, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`UpdateStatus School`))
      );
  }
}
