import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operatingDays'
})
export class OperatingDaysPipe implements PipeTransform {
  days = [
    'Sun', 'Mon', 'Tue',
    'Wed', 'Thu', 'Fri', 'Sat'
  ];

  transform(value: any, args?: any): any {
    const ret = value.split('')
      .map(val => this.days[val])
      .join(', ');

    return ret;
  }

}
