import { Component, OnInit } from '@angular/core';
import { SchoolAdminService } from '../../../core/school-admin.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-admin-school-list',
  templateUrl: './admin-school-list.component.html',
  styleUrls: ['./admin-school-list.component.css']
})
export class AdminSchoolListComponent implements OnInit {
  private token;
  private schoolAdminService;
  private cookieService;

  school;
  schools = [];

  // pagination
  private size = 5;
  total = 0;
  pageNum = 1;
  pagination = [];
  status = 'pending';

  constructor(
    cookieService: CookieService,
    schoolAdminService: SchoolAdminService
  ) {
    this.cookieService = cookieService;
    this.schoolAdminService = schoolAdminService;
  }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.getSchoolList();
  }

  getSchoolList() {

    this.schoolAdminService.getSchools(this.token, this.status, this.pageNum)
      .subscribe(response => {
        if (response && response.success) {
          this.schools = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });

  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;

    this.getSchoolList();
  }

  approve(schoolSelected) {
    this.school = schoolSelected;
    this.updateStatus('approved');
  }

  reject(schoolSelected, rejectedReason) {
    this.school = schoolSelected;
    this.updateStatus('rejected', rejectedReason);
  }

  updateStatus(status, rejectedReason = undefined) {

    const schoolId = this.school.schoolId;

    if (!schoolId) {
      return;
    }

    this.schoolAdminService.updateStatus(
      this.token,
      schoolId,
      status,
      (rejectedReason && {rejectedReason})
    )
    .subscribe(res => {
      if (res && res.success) {
        window.location.reload();
      }
    });
  }

  moveToPending(school, idx) {
    this.schoolAdminService.updateStatus(this.token, school.schoolId, 'pending')
      .subscribe(res => {
        if (res && res.success) {
          this.schools.splice(idx, 1);
          this.total = this.total - 1;
        }
      });
  }

  delete(school, idx) {
    this.schoolAdminService.delete(this.token, school.schoolId)
      .subscribe(res => {
        if (res && res.success) {
          this.schools.splice(idx, 1);
          this.total = this.total - 1;
        }
      });
  }

}
