import { Component, OnInit } from '@angular/core';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UnderconstructionModalComponent } from '../../core/underconstruction-modal/underconstruction-modal.component';
import { ChatService } from '../../core/chat.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manning-agency-app-messages',
  templateUrl: './manning-agency-app-messages.component.html',
  styleUrls: ['./manning-agency-app-messages.component.css']
})
export class ManningAgencyAppMessagesComponent implements OnInit {
  user;
  token;
  threadId;
  openedThread = {
    threadId: 0,
    userId: 0
  };
  readThreads = [];
  unreadThreads = [];
  unfilteredReadThreads = [];
  unfilteredUnreadThreads = [];
  myUserId;
  openedChat = false;
  search = '';
  userId;
  thread;

  constructor(
    private route: ActivatedRoute,
    private chatService: ChatService,
    private cookieService: CookieService,
    private headerService: ManningAgencyHeaderService
  ) { }

  ngOnInit() {
    this.headerService.changeHeader('messages');
    this.token = this.cookieService.get('token');
    this.user = JSON.parse(this.cookieService.get('user'));
    this.myUserId = +this.cookieService.get('userId');
    this.userId = +this.route.snapshot.queryParams.userId;

    this.chatService.getThreads(this.token)
      .subscribe(res => {
        if (res && res.success) {
          res.data.items.forEach(thread => {

            if (thread.hasUnread) {
              this.unreadThreads.push(thread);
            } else {
              this.readThreads.push(thread);
            }

            if (thread.userId === this.userId) {
              this.getMessages(thread);
            }

          });

          this.unfilteredReadThreads = this.readThreads;
          this.unfilteredUnreadThreads = this.unreadThreads;
        }
      });
  }

  getMessages(thread) {

    this.openedChat = true;
    this.openedThread = thread;

    this.thread = thread;

    if (thread.hasUnread) {
      this.chatService.markAsRead(this.token, thread.threadId)
        .subscribe(res => {

          this.readThreads.push(thread);
          this.unreadThreads = this.unreadThreads.filter(t => t.threadId !== thread.threadId);
          this.unreadThreads.sort((a, b) => b.messageId - a.messageId);


          this.unfilteredReadThreads.push(thread);
          this.unfilteredUnreadThreads = this.unfilteredUnreadThreads.filter(t => t.threadId !== thread.threadId);
          this.unfilteredUnreadThreads.sort((a, b) => b.messageId - a.messageId);
        });
    }

  }

  filterThreads() {

    if (!this.search || !this.search.length) {
      this.unreadThreads = this.unfilteredUnreadThreads;
      this.readThreads = this.unfilteredReadThreads;
      return;
    }

    this.unreadThreads = this.unfilteredUnreadThreads
      .filter(t => t.name.toLowerCase().includes(this.search.toLowerCase()));
    this.readThreads = this.unfilteredReadThreads
      .filter(t => t.name.toLowerCase().includes(this.search.toLowerCase()));

  }

}
