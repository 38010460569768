import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SchoolService } from '../../core/school.service';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-app-total-registered',
  templateUrl: './school-app-total-registered.component.html',
  styleUrls: ['./school-app-total-registered.component.css']
})
export class SchoolAppTotalRegisteredComponent implements OnInit {
  private size = 5;
  pageNum = 1;
  total = 0;
  token;
  schoolId;
  seafarers = [];
  pagination = [];
  status = null;
  year;
  course;

  @ViewChild('exportable') exportable: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.schoolId = this.cookieService.get('roleId');

    this.year = this.route.snapshot.queryParams['year'];
    this.course = this.route.snapshot.queryParams['course'];

    this.getSeafarerByStatus();
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;

    this.getSeafarerByStatus();
  }

  getSeafarerByStatus() {
    this.schoolService.getSeafarerByStatus(
      this.schoolId,
      this.status,
      this.token,
      this.year,
      this.course,
      this.pageNum
    )
    .subscribe(this.updateSeafarerData.bind(this));
  }

  updateSeafarerData(response) {
    if (response && response.success) {
      this.seafarers = response.data.items;
      this.total = response.data.total;
      this.pagination = new Array(Math.ceil(this.total / this.size));
    }
  }

  exportToPDF() {
    html2canvas(this.exportable.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = `registered-data-page-${this.pageNum}.png`;
      this.downloadLink.nativeElement.click();
    });
  }

}
