import { Component, OnInit } from '@angular/core';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';

@Component({
  selector: 'app-manning-agency-app-notifications',
  templateUrl: './manning-agency-app-notifications.component.html',
  styleUrls: ['./manning-agency-app-notifications.component.css']
})
export class ManningAgencyAppNotificationsComponent implements OnInit {

  constructor(
    private headerService: ManningAgencyHeaderService
  ) { }

  ngOnInit() {
    this.headerService.changeHeader('notifications');
  }

}
