import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-seafarers-location-map',
  templateUrl: './seafarers-location-map.component.html',
  styleUrls: ['./seafarers-location-map.component.css']
})
export class SeafarersLocationMapComponent implements OnInit {
  defaults = {
    lat: 14.5995,
    lng: 120.9842,
  };
  online = '../../../assets/img/circular-filled-shape-green.png';
  offline = '../../../assets/img/circular-filled-shape-red.png';
  @Input() seafarers = [];

  constructor() { }

  ngOnInit() {
  }

  getIcon(seafarer) {
    return seafarer.isOnline || seafarer.remarks === 'responded'
      ? this.online
      : this.offline;
  }

}
