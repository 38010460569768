
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyService } from '../../../core/manning-agency.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ManningAgencyHeaderService } from '../../../manning-agency/manning-agency-header.service';


@Component({
  selector: 'app-manning-agency-posts',
  templateUrl: './manning-agency-posts.component.html',
  styleUrls: ['./manning-agency-posts.component.css']
})
export class ManningAgencyPostsComponent implements OnInit {
  name;
  maId;
  token;
  manningAgencyId;
  search;
  page = 1;
  size = 1000;
  posts = [];
  seafarers = [];
  action = '';

  constructor(
    private route: ActivatedRoute,
    private headerService: ManningAgencyHeaderService,
    private cookieService: CookieService,
    private maService: ManningAgencyService,
    public location: Location
  ) { }

  ngOnInit() {
    this.headerService.changeHeader('posts');
    this.token = this.cookieService.get('token');
    this.manningAgencyId = this.route.snapshot.paramMap.get('id');
    observableForkJoin(
      this.maService.getPosts(this.token, this.manningAgencyId, this.search, this.page, this.size),
      this.maService.getJobs(this.token, this.manningAgencyId, this.search, this.page, this.size)
    )
      .subscribe(posts => {
        this.name = posts[0].data.items[0].name;
        this.posts = posts[0].data.items
          .concat(posts[1].data.items)
          .sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated));
      });
  }

  getLikers(type, post) {
    this.action = 'Likes';
    if (type === 'status') {
      this.maService.getPostLikers(this.token, post.maPostId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    } else {
      this.maService.getJobLikers(this.token, post.maJobId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    }
  }

  getComments(type, post) {
    this.action = 'Comments';
    if (type === 'status') {
      this.maService.getPostComments(this.token, post.maPostId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    } else {
      this.maService.getJobComments(this.token, post.maJobId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    }
  }

  getShares(type, post) {
    this.action = 'Shares';
    if (type === 'status') {
      this.maService.getPostShares(this.token, post.maPostId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    } else {
      this.maService.getJobShares(this.token, post.maJobId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    }
  }

  getApplicants(post) {
    this.action = 'Applicants';
    this.maService.getApplicants(this.token, post.maJobId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
  }

}
