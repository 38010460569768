import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.css']
})
export class PictureComponent implements OnInit {
  @Input() src;
  @Input() role = 'user';
  icons = {
    user: 'fa-user',
    school: 'fa-building-o',
    manning_agency: 'fa-build-o'
  };
  icon;

  constructor() { }

  ngOnInit() {
    this.icon = this.icons[this.role];
  }

}
