import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-app-student-chat',
  templateUrl: './school-app-student-chat.component.html',
  styleUrls: ['./school-app-student-chat.component.css']
})
export class SchoolAppStudentChatComponent implements OnInit {
  thread = null;

  constructor(
    public location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const threadId = this.route.snapshot.paramMap.get('id');
    const userId = this.route.snapshot.paramMap.get('userId');

    this.thread = {
      threadId,
      userId
    };
  }


}
