import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-app-unverified',
  templateUrl: './school-app-unverified.component.html',
  styleUrls: ['./school-app-unverified.component.css']
})
export class SchoolAppUnverifiedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
