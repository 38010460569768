import { Component, OnInit } from '@angular/core';
import { SchoolAdminService } from '../../../core/school-admin.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-admin-school-dashboard',
  templateUrl: './admin-school-dashboard.component.html',
  styleUrls: ['./admin-school-dashboard.component.css']
})
export class AdminSchoolDashboardComponent implements OnInit {
  token;
  dashboard = {
    approved: 0,
    pending: 0,
    rejected: 0
  };

  constructor(
    private cookieService: CookieService,
    private schoolAdminService: SchoolAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.schoolAdminService.getDashboard(this.token)
      .subscribe(response => {
        if (response && response.success) {
          this.dashboard = response.data;
        }
      });
  }

}
