import { Component, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import { SchoolStudentComponent } from '../../component/school-student/school-student.component';

@Component({
  selector: 'app-school-app-unregistered-students',
  templateUrl: './school-app-unregistered-students.component.html',
  styleUrls: ['./school-app-unregistered-students.component.css'],
})
export class SchoolAppUnregisteredStudentsComponent implements OnInit {
  @ViewChild('emailSentModal') emailSentModal: ModalDirective;
  @ViewChild(SchoolStudentComponent) studentApp: SchoolStudentComponent;
  header = 'UNACTIVATED STUDENT ACCOUNTS';
  total = 0;
  schoolId;
  labels = [
    'Surname', 'Given Name', 'Middle Initial', 'Year CCI/CAR',
    'Program', 'Email', 'Contact No.', 'Temporary Password'
  ];
  properties: Array<any> = [
    'lastName', 'firstName', 'middleName', 'yearDiploma',
    'course', 'email', 'phone', 'temp_password'
  ];
  actions = [
    {
      icon: 'fa-send-o',
      action: () => {}
    },
    {
      icon: 'fa-print',
      action: () => {}
    },
    {
      icon: 'fa-pencil',
      action: () => {}
    },
    {
      icon: 'fa-trash-o',
      action: () => {}
    }
  ];
  link = '/school/student/seafarer/';
  status = 'unverified';
  schoolName;

  constructor(
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.schoolId = this.cookieService.get('roleId');
    const user = JSON.parse(this.cookieService.get('user'));
    this.schoolName = user.name;

    this.actions[0].action = this.sendEmail.bind(this);
    this.actions[1].action = this.showRegistraionDetails.bind(this);
    this.actions[2].action = this.editSeafarer.bind(this);
    this.actions[3].action = this.deleteSeafarer.bind(this);
  }

  onSendTotal(total) {
    this.total = total;
  }

  sendEmail(seafarer) {
    this.studentApp.sendEmail(seafarer);
  }

  sendEmailToAll() {
    this.studentApp.sendEmailToAll(this.schoolId);
  }

  showRegistraionDetails (seafarer) {
    this.studentApp.showRegistrationDetails(seafarer);
  }

  editSeafarer(seafarer) {
    const labels = _.without(this.labels, 'Temporary Password');
    const properties = _.without(this.properties, 'temp_password');

    // TODO: validations like phone
    const props = _.map(labels, (label, idx) => ({
      label,
      property: properties[idx]
    }))

    this.studentApp.editSeafarer(seafarer, props);
  }

  deleteSeafarer(seafarer) {
    this.studentApp.showDeleteSeafarerModal(seafarer);
  }

}
