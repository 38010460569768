import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-school-app-left-side',
  templateUrl: './school-app-left-side.component.html',
  styleUrls: ['./school-app-left-side.component.css']
})
export class SchoolAppLeftSideComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

}
