import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MotherService } from './mother.service';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ManningAgencyService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  getPosts(token, manningAgencyId, search, postId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_post?manningAgencyId=${manningAgencyId}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (postId) {
      url += `&postId=${postId}`;
    }
    url += `&page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetPosts`))
      );
  }

  getJobs(token, manningAgencyId, search, jobId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_job?manningAgencyId=${manningAgencyId}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (jobId) {
      url += `&jobId=${jobId}`;
    }
    url += `&page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobs`))
      );
  }

  getPostLikers(token, postId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/ma_post/${postId}/likers?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetPostLikers`))
      );
  }

  getPostComments(token, postId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/ma_post/${postId}/comments?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetPostComments`))
      );
  }

  createReplyComment(token, type, commentId, comment) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };

    const jobOrPost = type === 'status' ? 'post' : 'job';
    const url = `${this.apiUrl}/ma_comment/${commentId}/${jobOrPost}`;

    return this.http.post<any>(url, {comment}, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`CreateReplyComment`))
      );
  }

  getPostShares(token, postId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/ma_post/${postId}/shares?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetPostShares`))
      );
  }

  getJobLikers(token, jobId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/ma_job/${jobId}/likers?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobLikers`))
      );
  }

  getJobComments(token, jobId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/ma_job/${jobId}/comments?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobComments`))
      );
  }

  getJobShares(token, jobId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/ma_job/${jobId}/shares?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobShares`))
      );
  }

  getApplicants(token, jobId, page = 1, size = 5) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/ma_job/${jobId}/applicants?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetApplicants`))
      );
  }

  createStatus(token, post) {
    const url = `${this.apiUrl}/ma_post/`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const requestForm = new FormData();
    requestForm.append('message', post.message);
    requestForm.append('youtube', post.youtube);
    Array.prototype.forEach.call(post.files, (file, i) =>
      requestForm.append(`photos`, file, file.name));
    return this.http.post<any>(url, requestForm, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('CreateStatus'))
      );
  }

  createJob(token, post) {
    const url = `${this.apiUrl}/ma_job/`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.post<any>(url, post, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('CreateJob'))
      );
  }

  createMA(ma) {
    const url = `${this.apiUrl}/manning_agency`;
    return this.http.post<any>(url, ma)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Register Manning Agency'))
      );
  }

  uploadImage(manningAgencyId, companyLogo) {
    const url = `${this.apiUrl}/manning_agency/${manningAgencyId}/upload_image`;
    const requestForm = new FormData();
    requestForm.append('manningAgency', companyLogo, companyLogo.name);
    return this.http.post<any>(url, requestForm)
      .pipe(
        catchError(this.handleError('Upload Company Logo'))
      );
  }

  forgotPassword(email) {
    const url = `${this.apiUrl}/manning_agency/forgot_password`;
    return this.http.post<any>(url, email)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Forgot Password'))
      );
  }

  resetPassword(data) {
    const url = `${this.apiUrl}/manning_agency/reset_password`;
    return this.http.post<any>(url, data)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Reset Password'))
      );
  }

  getRecentApplicants(token, maId = null, position = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_job/recent-applicants?size=1000`;

    if (maId) {
      url += `&manningAgencyId=${maId}`;
    }

    if (position) {
      url += `&position=${position}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('GetRecentApplicants'))
      );
  }

  getRecentShortlisted(token, maId = null, position = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_job/recent-shortlisted?size=1000`;

    if (maId) {
      url += `&manningAgencyId=${maId}`;
    }

    if (position) {
      url += `&position=${position}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('GetRecentShortlisted'))
      );
  }

  createInvite(token, invitation) {
    const url = `${this.apiUrl}/ma_job/invite`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.post<any>(url, invitation, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('CreateInvite'))
      );
  }

  updatePost(token, post, id) {
    const url = `${this.apiUrl}/ma_post/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.put<any>(url, post, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('UpdatePost'))
      );
  }

  updateJob(token, job) {
    const url = `${this.apiUrl}/ma_job/${job.maJobId}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.put<any>(url, job, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('UpdateJob'))
      );
  }

  deletePost(token, id) {
    const url = `${this.apiUrl}/ma_post/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.delete<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('DeletePost'))
      );
  }

  deleteJob(token, id) {
    const url = `${this.apiUrl}/ma_job/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.delete<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('DeleteJob'))
      );
  }

  unapplyApplicant(token, id, seafarerId) {
    const url = `${this.apiUrl}/ma_job/${id}/unapply/${seafarerId}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.post<any>(url, {}, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('UnapplyApplicant'))
      );
  }

  uninviteApplicant(token, id, seafarerId) {
    const url = `${this.apiUrl}/ma_job/${id}/uninvite/${seafarerId}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.post<any>(url, {}, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('UninviteApplicant'))
      );
  }

  saveTrainingCenter(token, tcs) {
    const url = `${this.apiUrl}/manning_agency/training_centers`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    return this.http.post<any>(url, {tcs}, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('SaveTrainingCenter'))
      );
  }

}
