import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-manning-agency-base',
  templateUrl: './manning-agency-base.component.html',
  styleUrls: ['./manning-agency-base.component.css']
})
export class ManningAgencyBaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  generatePassword(manningAgency) {
    const names = manningAgency.name.split(' ');
    const firstName = _.head(names).toLowerCase();
    const initials = _.map(names, name => _.head(_.toUpper(name))).join('');
    const year = moment().format('YYYY');

    return firstName + initials + year;
  }

}
