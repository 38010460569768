import { Component, OnInit, ViewChild } from '@angular/core';
import { SeafarerAdminService } from '../../core/seafarer-admin.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SeafarerViewAccessService } from '../../core/seafarer-view-access.service';

@Component({
  selector: 'app-manning-agency-app-applicant-profile',
  templateUrl: './manning-agency-app-applicant-profile.component.html',
  styleUrls: ['./manning-agency-app-applicant-profile.component.css']
})
export class ManningAgencyAppApplicantProfileComponent implements OnInit {
  @ViewChild('galleryModal') galleryModal: ModalDirective;
  token;
  seafarerId;
  seafarer = {
    userId: 0,
    imgUrl: '',
    firstName: '',
    middleName: '',
    lastName: '',
    lastRankOn: '',
    schoolGraduated: '',
    address: '',
    licenseCOC: '',
    vesselTypeExp: '',
    prevManningAgency: '',
    facebookLink: '',
    phone: ''
  };
  infoPage = 2;
  currentImg;
  docs = {
    coc: 'C.O.C',
    validPassport: 'Passport',
    seamanBook: 'Seaman Book',
    src: 'SRC',
    ssc: 'SSC'
  };
  canAccess = false;
  requested = false;
  requestMessage = 'REQUEST FOR AN ACCESS HERE.';

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private seafarerService: SeafarerAdminService,
    private seafarerViewAccessService: SeafarerViewAccessService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.seafarerId = this.route.snapshot.paramMap.get('id');

    this.seafarerViewAccessService.getAccess(this.token, this.seafarerId)
      .subscribe(res => {
        if (res && res.success) {
          this.requested = !!res.data.id;
          this.canAccess = res.data.isAccepted;

          if (this.requested) {
            this.requestMessage  = 'WAIT TO ACCEPT YOUR REQUEST.';
          }
        }
      });

      this.seafarerService.getSeafarerProfile(this.token, this.seafarerId)
        .subscribe(res => {
          if (res && res.success) {
            this.seafarer = res.data;
          }
        });
  }

  showImg(url) {
    this.currentImg = url;
    this.galleryModal.show();
  }

  requestAccess() {
    if (!this.requested) {
      this.requestMessage  = 'WAIT TO ACCEPT YOUR REQUEST.';

      this.seafarerViewAccessService.requestAccess(this.token, this.seafarerId)
        .subscribe(res => this.toastr.success('Requested for an access!'));
    }

    this.requested = true;
  }

}
