import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ManningAgencyService } from '../manning-agency.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  id;
  token;
  deleteFunction;
  index;
  data;
  service;
  message;
  seafarerId;
  callback;

  constructor(
    public bsModalRef: BsModalRef,
    private maService: ManningAgencyService
  ) { }

  ngOnInit() {
  }

  delete() {
    this[this.service][this.deleteFunction](this.token, this.id, this.seafarerId)
      .subscribe(() => {
        this.bsModalRef.hide(); 
        this.data.splice(this.index, 1);

        if (this.callback) {
          this.callback();
        }
      }); 
  }

}
