import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { SchoolService } from '../../../core/school.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-profile',
  templateUrl: './school-profile.component.html',
  styleUrls: ['./school-profile.component.css']
})
export class SchoolProfileComponent implements OnInit {
  token;
  schoolId;
  profile = {
    manning_agencyId: 0,
    name: '',
    email: '',
    contactPerson: '[{}]',
    operatingDays: '',
    companyLogo: '',
    about: '',
    address: '',
    operatingHours: '',
    telNumber: '',
    mobileNumber: '',
    poeaLicenseNumber: '',
  };

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.schoolId = this.route.snapshot.paramMap.get('id');

    this.schoolService.getProfile(this.token, this.schoolId)
      .subscribe(res => {
        if (res && res.success) {
          this.profile = res.data;
        }
      });
  }

}
