import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../core/manning-agency-admin.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-manning-agency-list-table',
  templateUrl: './manning-agency-list-table.component.html',
  styleUrls: ['./manning-agency-list-table.component.css']
})
export class ManningAgencyListTableComponent implements OnInit {
  private size = 5;
  pageNum = 1;
  total = 0;
  token;
  manning_agencies = [];
  pagination = [];
  @Output() sendTotal = new EventEmitter<any>();
  @Input() status = null;
  @Input() labels = [];
  @Input() properties = [];
  @Input() actions = [];

  constructor(
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyAdminService
  ) {
    this.token = this.cookieService.get('token');
  }

  ngOnInit() {
    this.getManningAgencies();
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;

    this.getManningAgencies();
  }

  getManningAgencies() {
    this.manningAgencyService.getMA(this.token, this.status, this.pageNum)
      .subscribe(this.updateDataTable.bind(this));
  }

  updateDataTable(response) {
    if (response && response.success) {
      this.manning_agencies = response.data.items;
      this.total = response.data.total;
      this.pagination = new Array(Math.ceil(this.total / this.size));

      this.sendTotal.emit(this.total);
    }
  }

  isArray(obj : any) {
    return Array.isArray(obj);
  }

  isFunction(fn) {
    return _.isFunction(fn);
  }

}
