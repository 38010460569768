import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ManningAgencyService } from '../manning-agency.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-invite-seafarer-modal',
  templateUrl: './invite-seafarer-modal.component.html',
  styleUrls: ['./invite-seafarer-modal.component.css']
})
export class InviteSeafarerModalComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false
  };
  minDate = new Date();
  token;
  seafarer;
  index;
  position = '';
  invitation = {
    maJobId: null,
    seafarerId: null,
    maApplicantId: null,
    dateInvitation: null,
    timeInvitation: '',
    lookFor: ''
  };
  data;
  removeData;
  ranks;

  constructor(
    public bsModalRef: BsModalRef,
    private maService: ManningAgencyService
  ) { }

  ngOnInit() {
  }

  invite() {
    this.maService.createInvite(this.token, this.invitation)
      .subscribe(() => {
        this.bsModalRef.hide();
        if (this.removeData) {
          this.data.splice(this.index, 1);

          this.ranks.forEach(r => {
            if (r.position === this.seafarer.position) {
              r.count--;
            }
          });
        }
      });
  }

  isValidInvitation() {
    return (
      this.invitation.dateInvitation &&
      this.invitation.timeInvitation.length &&
      this.invitation.lookFor.length
    );
  }

}
