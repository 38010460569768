import { StarterComponent } from './starter/starter.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SeafarersComponent } from './admin/seafarers/seafarers.component';
import { SeafarersTotalComponent } from './admin/seafarers/seafarers-total/seafarers-total.component';
import { SeafarersDetailsComponent } from './admin/seafarers/seafarers-details/seafarers-details.component';
import { SeafarersBannedComponent } from './admin/seafarers/seafarers-banned/seafarers-banned.component';
import { SeafarersTrainingComponent } from './admin/seafarers/seafarers-training/seafarers-training.component';
import { SeafarersReviewingComponent } from './admin/seafarers/seafarers-reviewing/seafarers-reviewing.component';
import { SeafarersOnboardComponent } from './admin/seafarers/seafarers-onboard/seafarers-onboard.component';
import { SeafarersVacationComponent } from './admin/seafarers/seafarers-vacation/seafarers-vacation.component';
import { SeafarersLandbaseComponent } from './admin/seafarers/seafarers-landbase/seafarers-landbase.component';
import { SeafarersOnlineComponent } from './admin/seafarers/seafarers-online/seafarers-online.component';
import { SeafarersMessagesComponent } from './admin/seafarers/seafarers-messages/seafarers-messages.component';
import { ManningAgencyComponent } from './admin/manning-agency/manning-agency.component';
import { ManningAgencyContentComponent } from './admin/manning-agency/manning-agency-content/manning-agency-content.component';
import { ManningAgencyApprovedComponent } from './admin/manning-agency/manning-agency-approved/manning-agency-approved.component';
import { ManningAgencyPendingComponent } from './admin/manning-agency/manning-agency-pending/manning-agency-pending.component';
import { ManningAgencyRejectedComponent } from './admin/manning-agency/manning-agency-rejected/manning-agency-rejected.component';
import { ManningAgencyJobsListComponent } from './admin/manning-agency/manning-agency-jobs-list/manning-agency-jobs-list.component';
import { ManningAgencyJobsAppliedComponent } from './admin/manning-agency/manning-agency-jobs-applied/manning-agency-jobs-applied.component';
import { ManningAgencySubscriptionComponent } from './admin/manning-agency/manning-agency-subscription/manning-agency-subscription.component';
import { ManningAgencyMessagesComponent } from './admin/manning-agency/manning-agency-messages/manning-agency-messages.component';
import { ManningAgencyPendingProfileComponent } from './admin/manning-agency/manning-agency-pending-profile/manning-agency-pending-profile.component';
import { ManningAgencyProfileComponent } from './admin/manning-agency/manning-agency-profile/manning-agency-profile.component';
import { ManningAgencyPostsComponent } from './admin/manning-agency/manning-agency-posts/manning-agency-posts.component';
import { SeafarersContentComponent } from './admin/seafarers/seafarers-content/seafarers-content.component';
import { StarterContentComponent } from './starter/starter-content/starter-content.component';
import { ManningAgencyAppComponent } from './manning-agency/manning-agency-app/manning-agency-app.component';
import { ManningAgencyNewPostsComponent } from './manning-agency/manning-agency-new-posts/manning-agency-new-posts.component';
import { ManningAgencyAppPostsComponent } from './manning-agency/manning-agency-app-posts/manning-agency-app-posts.component';
import { ManningAgencyShortlistComponent } from './manning-agency/manning-agency-shortlist/manning-agency-shortlist.component';
import { ManningAgencyAppApplicantsComponent } from './manning-agency/manning-agency-app-applicants/manning-agency-app-applicants.component';
import { ManningAgencyAppNotificationsComponent } from './manning-agency/manning-agency-app-notifications/manning-agency-app-notifications.component';
import { ManningAgencyAppProfileComponent } from './manning-agency/manning-agency-app-profile/manning-agency-app-profile.component';
import { ManningAgencyAppMessagesComponent } from './manning-agency/manning-agency-app-messages/manning-agency-app-messages.component';
import { ManningAgencyAppFindSeamanComponent } from './manning-agency/manning-agency-app-find-seaman/manning-agency-app-find-seaman.component';
import { SessionService } from './core/session.service';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { ManningAgencyLoginComponent } from './manning-agency/manning-agency-login/manning-agency-login.component';
import { ManningAgencyRegistrationComponent } from './manning-agency/manning-agency-registration/manning-agency-registration.component';
import { ManningAgencyUnverifiedComponent } from './manning-agency/manning-agency-unverified/manning-agency-unverified.component';
import { ManningAgencyForgotPasswordComponent } from './manning-agency/manning-agency-forgot-password/manning-agency-forgot-password.component';
import { ManningAgencyResetPasswordComponent } from './manning-agency/manning-agency-reset-password/manning-agency-reset-password.component';
import { ManningAgencyAppApplicantProfileComponent } from './manning-agency/manning-agency-app-applicant-profile/manning-agency-app-applicant-profile.component';
import { ManningAgencyAppPostsDetailsComponent } from './manning-agency/manning-agency-app-posts-details/manning-agency-app-posts-details.component';
import { ManningAgencyThankYouComponent } from './manning-agency/manning-agency-thank-you/manning-agency-thank-you.component';
import { MetaGuard } from 'ng2-meta';
import { ManningAgencyFbsharePostsComponent } from './manning-agency-fbshare-posts/manning-agency-fbshare-posts.component';
import { ManningAgencyChatMessagesComponent } from './admin/manning-agency/manning-agency-chat-messages/manning-agency-chat-messages.component';
import { SchoolAppComponent } from './school/school-app/school-app.component';
import { SchoolAppLoginComponent } from './school/school-app-login/school-app-login.component';
import { SchoolAppRegistrationComponent } from './school/school-app-registration/school-app-registration.component';
import { SchoolAppStudentDashboardComponent } from './school/school-app-student-dashboard/school-app-student-dashboard.component';
import { SchoolAppAlumniDashboardComponent } from './school/school-app-alumni-dashboard/school-app-alumni-dashboard.component';
import { SchoolAppStudentMessagesComponent } from './school/school-app-student-messages/school-app-student-messages.component';
import { SchoolAppStudentComponent } from './school/school-app-student/school-app-student.component';
import { SchoolAppStudentChatComponent } from './school/school-app-student-chat/school-app-student-chat.component';
import { SchoolAppStudentUnderTrainingComponent } from './school/school-app-student-under-training/school-app-student-under-training.component';
import { SchoolAppStudentOnBoardComponent } from './school/school-app-student-on-board/school-app-student-on-board.component';
import { SchoolAppStudentOnVacationComponent } from './school/school-app-student-on-vacation/school-app-student-on-vacation.component';
import { SchoolAppStudentHiredLandBaseComponent } from './school/school-app-student-hired-land-base/school-app-student-hired-land-base.component';
import { SchoolAppStudentGraduateComponent } from './school/school-app-student-graduate/school-app-student-graduate.component';
import { SchoolAppUnverifiedComponent } from './school/school-app-unverified/school-app-unverified.component';
import { SchoolAppTotalRegisteredComponent } from './school/school-app-total-registered/school-app-total-registered.component';
import { AdminSchoolDashboardComponent } from './admin/school/admin-school-dashboard/admin-school-dashboard.component';
import { AdminSchoolComponent } from './admin/school/admin-school.component';
import { AdminSchoolPendingListComponent } from './admin/school/admin-school-pending-list/admin-school-pending-list.component';
import { AdminSchoolApprovedListComponent } from './admin/school/admin-school-approved-list/admin-school-approved-list.component';
import { AdminSchoolRejectedListComponent } from './admin/school/admin-school-rejected-list/admin-school-rejected-list.component';
import { SchoolProfileComponent } from './admin/school/school-profile/school-profile.component';
import { SchoolAppUnregisteredStudentsComponent } from './school/school-app-unregistered-students/school-app-unregistered-students.component';
import { SchoolAppRegisterStudentComponent } from './school/school-app-register-student/school-app-register-student.component';
import { SeafarersEmergencyComponent } from './admin/seafarers/seafarers-emergency/seafarers-emergency.component';
import { ManningAgencyUnactivatedComponent } from './admin/manning-agency/manning-agency-unactivated/manning-agency-unactivated.component';
import { SchoolForgotPasswordComponent } from './school/school-forgot-password/school-forgot-password.component';
import { SeafarerDeletePageComponent } from './seafarer/seafarer-delete-page/seafarer-delete-page.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', redirectTo: 'manning-agency/new-post', pathMatch: 'full', canActivate: [SessionService], data: {roles: ['manning-agency']} },
      { path: 'admin/login', component: AdminLoginComponent },
      { path: 'seafarer/delete', component: SeafarerDeletePageComponent },
      { path: 'manning-agency/login', component: ManningAgencyLoginComponent },
      { path: 'manning-agency/register', component: ManningAgencyRegistrationComponent },
      { path: 'manning-agency/unverified', component: ManningAgencyUnverifiedComponent },
      { path: 'manning-agency/forgot-password', component: ManningAgencyForgotPasswordComponent },
      { path: 'manning-agency/thank-you/:id', component: ManningAgencyThankYouComponent },
      { path: 'manning-agency/forgot-password/:token', component: ManningAgencyResetPasswordComponent },
      { path: 'seafarer/forgot-password/:token', component: ManningAgencyResetPasswordComponent },
      { path: 'manning-agency/share-fb/posts', canActivate: [MetaGuard], component: ManningAgencyFbsharePostsComponent},
      { path: 'school/login', component: SchoolAppLoginComponent },
      { path: 'school/register', component: SchoolAppRegistrationComponent },
      { path: 'school/unverified', component: SchoolAppUnverifiedComponent },
      { path: 'school/forgot-password', component: SchoolForgotPasswordComponent },
      { path: 'school/forgot-password/:token', component: ManningAgencyResetPasswordComponent },
      { path: 'admin/manning-agency/register', component: ManningAgencyRegistrationComponent, canActivate: [SessionService], data: {roles: ['admin']} },
      {
        path: 'admin',
        component: StarterComponent,
        canActivate: [SessionService], data: {roles: ['admin']},
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [SessionService], data: {roles: ['admin']} },
          { path: 'dashboard', component: StarterContentComponent, canActivate: [SessionService], data: {roles: ['admin']} },
          {
            path: 'seafarers', component: SeafarersComponent,
            children: [
              {
                path: '', component: SeafarersContentComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'total',
                component: SeafarersTotalComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'banned',
                component: SeafarersBannedComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'training',
                component: SeafarersTrainingComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'reviewing',
                component: SeafarersReviewingComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'onboard',
                component: SeafarersOnboardComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'vacation',
                component: SeafarersVacationComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'landbase',
                component: SeafarersLandbaseComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              }
              ,
              {
                path: 'online',
                component: SeafarersOnlineComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'messages',
                component: SeafarersMessagesComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'emergency',
                component: SeafarersEmergencyComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: ':id',
                component: SeafarersDetailsComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              }
            ]
          },
          {
            path: 'manning-agency', component: ManningAgencyComponent,
            children: [
              {
                path: '', component: ManningAgencyContentComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'approved',
                component: ManningAgencyApprovedComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'pending',
                component: ManningAgencyPendingComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'rejected',
                component: ManningAgencyRejectedComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'jobs-posted',
                component: ManningAgencyJobsListComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'jobs-applied',
                component: ManningAgencyJobsAppliedComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'subscription',
                component: ManningAgencySubscriptionComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'unactivated',
                component: ManningAgencyUnactivatedComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'register',
                component: ManningAgencyRegistrationComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'messages',
                component: ManningAgencyMessagesComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'messages/:id',
                component: ManningAgencyChatMessagesComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: ':id',
                component: ManningAgencyProfileComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'pending/:id', component: ManningAgencyPendingProfileComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'posts/:id', component: ManningAgencyPostsComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              }
            ]
          },
          {
            path: 'school', component: AdminSchoolComponent,
            children: [
              {
                path: '',
                component: AdminSchoolDashboardComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'pending',
                component: AdminSchoolPendingListComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'approved',
                component: AdminSchoolApprovedListComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'rejected',
                component: AdminSchoolRejectedListComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: ':id',
                component: SchoolProfileComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
            ],
          },
          {
            path: 'manning-agency/messages/:id', component: ManningAgencyComponent,
            children: [
              {
                path: ':id',
                component: ManningAgencyChatMessagesComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              }
            ]
          },
          {
            path: 'manning-agency/pending/:id', component: ManningAgencyComponent,
            children: [
              {
                path: ':id',
                component: ManningAgencyPendingProfileComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              }
            ]
          },
          {
            path: 'manning-agency/approved/:id', component: ManningAgencyComponent,
            children: [
              {
                path: ':id',
                component: ManningAgencyProfileComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              },
              {
                path: 'posts',
                component: ManningAgencyPostsComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              }
            ]
          },
          {
            path: 'manning-agency/posts/:id', component: ManningAgencyComponent,
            children: [
              {
                path: ':id',
                component: ManningAgencyPostsComponent,
                canActivate: [SessionService], data: {roles: ['admin']}
              }
            ]
          },
        ]
      },
      {
        path: 'manning-agency',
        component: ManningAgencyAppComponent,
        canActivate: [SessionService], data: {roles: ['manning-agency']},
        children: [
          { path: '', redirectTo: 'new-post', pathMatch: 'full' },
          {
            path: 'new-post',
            component: ManningAgencyNewPostsComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'posts',
            component: ManningAgencyAppPostsComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'posts/:id/:type',
            component: ManningAgencyAppPostsComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'shortlist',
            component: ManningAgencyShortlistComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'applicants',
            component: ManningAgencyAppApplicantsComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'applicants/:id',
            component: ManningAgencyAppApplicantProfileComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'notifications',
            component: ManningAgencyAppNotificationsComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'profile',
            component: ManningAgencyAppProfileComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'messages',
            component: ManningAgencyAppMessagesComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          },
          {
            path: 'find-seaman',
            component: ManningAgencyAppFindSeamanComponent,
            canActivate: [SessionService], data: {roles: ['manning-agency']}
          }
        ]
      },
      {
        path: 'school',
        component: SchoolAppComponent,
        canActivate: [SessionService], data: {roles: ['school']},
        children: [
          { path: '', redirectTo: 'student', pathMatch: 'full', canActivate: [SessionService], data: {roles: ['school']} },
          { path: 'student',
            component: SchoolAppStudentComponent,
            canActivate: [SessionService], data: {roles: ['school']},
            children: [
              {
                path: '',
                component: SchoolAppStudentDashboardComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'messages',
                component: SchoolAppStudentMessagesComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'under-training',
                component: SchoolAppStudentUnderTrainingComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'on-board',
                component: SchoolAppStudentOnBoardComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'on-vacation',
                component: SchoolAppStudentOnVacationComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'hired-land-base',
                component: SchoolAppStudentHiredLandBaseComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'graduate',
                component: SchoolAppStudentGraduateComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'register',
                component: SchoolAppRegisterStudentComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'registered',
                component: SchoolAppTotalRegisteredComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'unactivated',
                component: SchoolAppUnregisteredStudentsComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'seafarer/:id',
                component: SeafarersDetailsComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'messages/:id/:userId',
                component: SchoolAppStudentChatComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
            ]
          },
          { path: 'alumni',
            component: SeafarersComponent,
            canActivate: [SessionService],
            data: {roles: ['school']},
            children: [
              {
                path: '', component: SeafarersContentComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'total',
                component: SeafarersTotalComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'banned',
                component: SeafarersBannedComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'training',
                component: SeafarersTrainingComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'reviewing',
                component: SeafarersReviewingComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'onboard',
                component: SeafarersOnboardComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'vacation',
                component: SeafarersVacationComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'landbase',
                component: SeafarersLandbaseComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              }
              ,
              {
                path: 'online',
                component: SeafarersOnlineComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'messages',
                component: SeafarersMessagesComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: 'emergency',
                component: SeafarersEmergencyComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              },
              {
                path: ':id',
                component: SeafarersDetailsComponent,
                canActivate: [SessionService], data: {roles: ['school']}
              }
            ]
          },
        ]
      }
    ])
  ],
  declarations: [],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
