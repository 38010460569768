import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChangePasswordModalComponent } from '../../core/change-password-modal/change-password-modal.component';

@Component({
  selector: 'app-school-app-navbar',
  templateUrl: './school-app-navbar.component.html',
  styleUrls: ['./school-app-navbar.component.css']
})
export class SchoolAppNavbarComponent implements OnInit {
  bsModalRef: BsModalRef;
  token;
  school;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: BsModalService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.school = JSON.parse(this.cookieService.get('user'));
  }

  logout() {
    const role = this.cookieService.get('role');
    this.authService.logout(role);
  }

  showChangePasswordModal() {
    const initialState = {
      token: this.token,
      role: 'school'
    };

    this.bsModalRef = this.modalService.show(ChangePasswordModalComponent, {initialState});
  }

}
