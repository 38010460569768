import { Component, OnInit, ViewChild } from '@angular/core';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { ManningAgencyAdminService } from '../../core/manning-agency-admin.service';
import { ManningAgencyService } from '../../core/manning-agency.service';
import { ManningAgencySubscriptionModalComponent } from '../manning-agency-subscription-modal/manning-agency-subscription-modal.component';
import * as moment from 'moment';
import * as _ from 'lodash';

declare const $: any;

@Component({
  selector: 'app-manning-agency-app-profile',
  templateUrl: './manning-agency-app-profile.component.html',
  styleUrls: ['./manning-agency-app-profile.component.css']
})
export class ManningAgencyAppProfileComponent implements OnInit {
  @ViewChild('aboutModal') aboutModal: ModalDirective;
  @ViewChild('poeaModal') poeaModal: ModalDirective;
  @ViewChild('contactModal') contactModal: ModalDirective;
  @ViewChild('basicInfoModal') basicInfoModal: ModalDirective;
  @ViewChild('cropImageModal') cropImageModal: ModalDirective;
  @ViewChild('basicSubscriptionModal') basicSubscriptionModal: ModalDirective;
  @ViewChild('premiumSubscriptionModal') premiumSubscriptionModal: ModalDirective;
  @ViewChild('affiliatedModal') affiliatedModal: ModalDirective;

  bsModalRef: BsModalRef;
  token;
  maId;
  file;
  uploadCrop;
  about = '';
  profile = {
    name: '',
    address: '',
    email: '',
    telNum: [
      {
        value: ''
      }
    ],
    telNumber: '',
    mobNum: [
      {
        value: ''
      }
    ],
    mobileNumber: '',
    cp: [
      {
        name: '',
        rank: '',
        phone: ''
      }
    ],
    subscriptionName: '',
    operatingHours: '',
    fromTime: '',
    toTime: '',
    optDays: [false, false, false, false, false, false, false],
    lat: 14.5995,
    lng: 120.9842,
    contactPerson: '[{}]',
    operatingDays: '',
    companyLogo: '',
    about: '',
    poeaLicenseNumber: '',
    dateAccepted: null,
    trainingCenters: []
  };
  days = [
    'Sun', 'Mon', 'Tue',
    'Wed', 'Thu', 'Fri', 'Sat'
  ];
  newProfile = {

  };
  tcToDelete = [];
  tcListInModal = [];

  constructor(
    private headerService: ManningAgencyHeaderService,
    public location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private maService: ManningAgencyService,
    private manningAgencyService: ManningAgencyAdminService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit() {
    this.headerService.changeHeader('profile');
    this.token = this.cookieService.get('token');
    this.maId = this.cookieService.get('roleId');
    this.manningAgencyService.getProfile(this.token, this.maId)
      .subscribe(res => {
        if (res && res.success) {
          this.profile = Object.assign({},  this.profile, res.data);
          const time = res.data.operatingHours.split(' - ');
          this.profile.fromTime = time[0];
          this.profile.toTime = time[1];
          res.data.operatingDays
            .split('')
            .forEach(val => this.profile.optDays[val] = true);
          this.profile.telNum = res.data.telNumber.substr(1, res.data.telNumber.length - 2)
            .split(',')
            .map(telNum => ({value: telNum}));
          this.profile.mobNum = res.data.mobileNumber.substr(1, res.data.mobileNumber.length - 2)
            .split(',')
            .map(mobNum => ({value: mobNum}));
          this.profile.cp = JSON.parse(res.data.contactPerson);

          if (!this.profile.trainingCenters.length) {
            return this.insertNewTrainingCenterRow();
          }

          this.tcListInModal = _.cloneDeep(this.profile.trainingCenters);
        }
      });

    this.uploadCrop = $('#croppie').croppie({
        enforceBoundary: true,
        mouseWheelZoom: 'ctrl',
        maxZoom: 1.5,
        viewport: {
          width: 200,
          height: 200
        },
        boundary: {
          width: 300,
          height: 300
        }
      });
  }

  addTelNum() {
    this.profile.telNum.push({value: ''});
  }

  addMobNum() {
    this.profile.mobNum.push({value: ''});
  }

  addContact() {
    this.profile.cp.push({
      name: '',
      rank: '',
      phone: ''
    });
  }

  readyFiles(event) {
    this.file = null;
    const files: FileList = event.target.files;
    if (files.length && files[0]) {
      this.file = files[0];
      if (this.cropImageModal.isShown) {
        this.showCroppie();
      } else {
        this.cropImageModal.show();
      }
    }
  }

  showDays() {
    const days = this.profile.optDays
      .map((day, i) => day && this.days[i])
      .filter(d => d);
    return days.join(', ');
  }

  showCroppie() {
    if (!this.file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (imgSrc: any) => {
      this.uploadCrop.croppie('bind', {
        url: imgSrc.target.result,
        points: [100, 100, 100, 100]
      })
        .then(() => {
          $('.cr-slider').attr('max', '1.5000');
          this.uploadCrop = this.uploadCrop.croppie('setZoom', 1.0000);
          $('.cr-slider').attr('min', '0.2029');
          $('.cr-slider').attr('aria-valuenow', '0.2029');
        });
    };
    reader.readAsDataURL(this.file);
  }

  upload() {
    this.uploadCrop.croppie('result', {
      type: 'base64',
      size: 'viewport',
      circle: false,
      format: 'jpeg'
    }).then((resp) => {
      const arr = resp.split(',');
      const byteChars = atob(arr[1]);
      const bytes = new Uint8Array(byteChars.length);
      for (let i = 0; i < byteChars.length; i++) {
        bytes[i] = byteChars.charCodeAt(i);
      }
      const file = new File([bytes], this.file.name, { type: 'image/jpg' });
      this.maService.uploadImage(this.maId, file)
        .subscribe(res => {
          if (res && res.success) {
            this.profile.companyLogo = res.data.url;
          }
          this.cropImageModal.hide();
        });
    });
  }

  updateLocation(event) {
    this.profile.lat = event.coords.lat;
    this.profile.lng = event.coords.lng;
  }

  updateAbout() {
    const data = {
      email: this.profile.email,
      about: this.about
    };

    this.manningAgencyService.update(this.token, this.maId, data)
      .subscribe(res => {
        if (res && res.success) {
          this.profile.about = data.about;
        }
        this.aboutModal.hide();
      });
  }

  isValid() {
    return (
      this.profile.name.length &&
      this.profile.address.length &&
      this.showDays().length &&
      this.profile.fromTime &&
      this.profile.toTime &&
      this.profile.telNum.some(telNum => !!telNum.value.length) &&
      this.profile.mobNum.some(mobNum => !!mobNum.value.length) &&
      this.profile.poeaLicenseNumber
    );
  }

  isValidContact() {
    return this.profile.cp
      .some(person => !!(person.name.length && person.phone.length && person.rank.length));
  }

  updateBasic() {
    this.profile.operatingDays = this.profile.optDays
      .map((day, i) => day && i)
      .filter(d => d)
      .join('');
    this.profile.operatingHours = `${this.profile.fromTime} - ${this.profile.toTime}`;
    const mobileNum = this.profile.mobNum
      .filter(mobNum => !!mobNum.value.length)
      .map(mobNum => mobNum.value)
      .join(',');
    this.profile.mobileNumber = `[${mobileNum}]`;
    const teleNum = this.profile.telNum
      .filter(telNum => !!telNum.value.length)
      .map(telNum => telNum.value)
      .join(',');
    this.profile.telNumber = `[${teleNum}]`;
    this.manningAgencyService.update(this.token, this.maId, this.profile)
      .subscribe(res => {
        this.basicInfoModal.hide();
        this.poeaModal.hide();
      });
  }

  updateContact() {
    this.profile.contactPerson = JSON.stringify(this.profile.cp);
    this.manningAgencyService.update(this.token, this.maId, this.profile)
      .subscribe(res => {
        this.contactModal.hide();
      });
  }

  checkSubscription(plan) {
    return this.profile.subscriptionName && this.profile.subscriptionName.toLowerCase().includes(plan);
  }

  isPremium() {
    return this.checkSubscription('premium');
  }

  isBasic() {
    return this.checkSubscription('basic');
  }

  isTrial() {
    return this.checkSubscription('trial');
  }

  showBasicSubscription() {
    this.showSubscription('basic');
  }

  showPremiumSubscription() {
    this.showSubscription('premium');
  }

  showSubscription(type) {
    const initialState = {
      type
    };

    this.bsModalRef = this.bsModalService.show(ManningAgencySubscriptionModalComponent, {initialState});
  }

  deleteTrainingCenter(tc, idx) {
    if (tc.maTrainingCenterId) {
      tc.dateDeleted = moment().format();
      this.tcToDelete.push(tc);
    }

    this.tcListInModal.splice(idx, 1);

    if (!this.tcListInModal.length) {
      this.insertNewTrainingCenterRow();
    }
  }

  saveTrainingCenter() {
    this.tcListInModal = this.tcListInModal
      .filter(tc => !!tc.training_center && tc.training_center.length);

      this.tcListInModal.forEach(tc => tc.manning_agencyId = this.maId);

    this.maService.saveTrainingCenter(this.token, this.tcListInModal.concat(this.tcToDelete))
        .subscribe(res => {
          this.affiliatedModal.hide();
          this.profile.trainingCenters = _.cloneDeep(this.tcListInModal);
        });
  }

  hasTrainingCenters() {
    return this.profile.trainingCenters.filter(tc => tc.training_center).length;
  }

  insertNewTrainingCenterRow() {
    this.tcListInModal.push({
      training_center: null
    });
  }

}
