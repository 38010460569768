import { Component, OnInit, Sanitizer } from '@angular/core';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ManningAgencyService } from '../../core/manning-agency.service';
import { CookieService } from 'ngx-cookie-service';

import * as positions from '../../core/constants/positions.json';
import * as shiptypes from '../../core/constants/shiptype.json';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../../core/subscription.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-manning-agency-new-posts',
  templateUrl: './manning-agency-new-posts.component.html',
  styleUrls: ['./manning-agency-new-posts.component.css']
})
export class ManningAgencyNewPostsComponent implements OnInit {
  token;
  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false
  };
  minDate = new Date();
  active = 'status';
  message: string = '';
  media: string;
  youtubeLink: string;
  files: any = [];
  galleryFiles: any = [];
  postDisable = false;
  newJob = {
    position: '',
    typeOfShip: '',
    destination: '',
    salary: '',
    salaryCurrency: '',
    isUrgent: false,
    notes: '',
    expiration: ''
  };
  positions;
  shiptypes;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private maService: ManningAgencyService,
    private cookieService: CookieService,
    private headerService: ManningAgencyHeaderService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.headerService.changeHeader('new-post');
    this.token = this.cookieService.get('token');
    this.positions = positions;
    this.shiptypes = shiptypes;
  }

  cleanUrl(url) {
    url = url.replace('watch?v=', 'embed/');
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  readyFiles(event) {
    const files: FileList = event.target.files;
    if (files.length) {
      Array.prototype.forEach.call(files, file => {
        if (this.files.length > 2) {
          return;
        }
        this.files.push(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.galleryFiles.push({ name: file.name, imgSrc: reader.result });
        };
        reader.readAsDataURL(file);
      });
      this.media = 'images';
    }
  }

  post() {
    this.postDisable = true;
    if (this.active === 'status') {
      const newPost = {
        message: this.message,
        youtube: '',
        files: []
      };
      if (this.media === 'youtube') {
        newPost.youtube = this.youtubeLink;
      } else {
        newPost.files = this.files;
      }
      this.maService.createStatus(this.token, newPost)
        .subscribe(response => {
          if (response && response.success) {
            this.toastr.success(response.data.message);
            this.router.navigate(['/manning-agency/posts']);
          }
          this.postDisable = false;
        });
    } else {
      this.maService.createJob(this.token, this.newJob)
        .subscribe(response => {
          if (response && response.success) {
            this.toastr.success(response.data.message);
            this.router.navigate(['/manning-agency/posts']);
          }
          this.postDisable = false;
        });
    }
  }

  removeImage(i) {
    Array.prototype.splice.call(this.files, i, 1);
    Array.prototype.splice.call(this.galleryFiles, i, 1);
  }

}
