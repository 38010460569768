import { Component, OnInit } from '@angular/core';
import { SeafarerAdminService } from '../../../core/seafarer-admin.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-manning-agency-subscription',
  templateUrl: './manning-agency-subscription.component.html',
  styleUrls: ['./manning-agency-subscription.component.css']
})
export class ManningAgencySubscriptionComponent implements OnInit {
  private token;
  data = {
    trial: [],
    basic: [],
    premium: [],
  };

  constructor(
    private cookieService: CookieService,
    private seafarerService: SeafarerAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.seafarerService.getSubscriptionPageData(this.token)
      .subscribe(response => {
        if (response && response.success) {
          this.data = response.data;
        }
      });
  }

}
