import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manning-agency-unverified',
  templateUrl: './manning-agency-unverified.component.html',
  styleUrls: ['./manning-agency-unverified.component.css']
})
export class ManningAgencyUnverifiedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
