import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MotherService } from './mother.service';

@Injectable()
export class ManningAgencyAdminService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  getDashboard(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/manning_agency/admin-dashboard`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetDashboard`))
      );
  }

  getMA(token, status, page = 1) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/manning_agency/${status}?page=${page}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetMA`))
      );
  }

  update(token, maId, data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/manning_agency/${maId}`;

    return this.http.put<any>(url, data, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Update MA`))
      );
  }

  delete(token, maId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/manning_agency/${maId}`;

    return this.http.delete<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Delete MA`))
      );
  }

  getJobs(token, page = 1, sortBy = 'date', notExpired = 1) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_job?page=${page}&sortBy=${sortBy}&notExpired=${notExpired}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobs`))
      );
  }

  getJobsApplied(token, page = 1, size = 10, from = null, to = null, maId = null, position = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_job/admin-applied?page=${page}&size=${size}`;

    if (from) {
      url += `&from=${from}`;
    }

    if (to) {
      url += `&to=${to}`;
    }

    if (maId) {
      url += `&manningAgencyId=${maId}`;
    }

    if (position) {
      url += `&position=${position}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobsApplied`))
      );
  }

  getJobsShortlisted(token, page = 1, size = 10, from = null, to = null, maId = null, position = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_job/admin-shortlisted?page=${page}&size=${size}`;

    if (from) {
      url += `&from=${from}`;
    }

    if (to) {
      url += `&to=${to}`;
    }

    if (maId) {
      url += `&manningAgencyId=${maId}`;
    }

    if (position) {
      url += `&position=${position}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobsShortlisted`))
      );
  }

  getJobsAppliedCount(token, from = null, to = null, maId = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_job/applied-count?1=1`;

    if (from) {
      url += `&from=${from}`;
    }

    if (to) {
      url += `&to=${to}`;
    }

    if (maId) {
      url += `&manningAgencyId=${maId}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobsAppliedCount`))
      );
  }

  getJobsShortlistedCount(token, from = null, to = null, maId = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/ma_job/shortlisted-count?1=1`;

    if (from) {
      url += `&from=${from}`;
    }

    if (to) {
      url += `&to=${to}`;
    }

    if (maId) {
      url += `&manningAgencyId=${maId}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetJobsShortlistedCount`))
      );
  }

  getProfile(token, maId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/manning_agency/${maId}/profile`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Get MA Profile`))
      );
  }

  updateStatus(token, maId, status, data = {}) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/manning_agency/${maId}/${status}`;

    return this.http.put<any>(url, data, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`UpdateStatus MA`))
      );
  }

  sendWelcomeEmail(token, manning_agencyId = null) {
    const url = `${this.apiUrl}/manning_agency/send_welcome_email`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const payload = manning_agencyId ? {manning_agencyId} : {};

    return this.http.post<any>(url, payload, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Send Welcome Email to Manning Agency'))
      );
  }

}
