import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SchoolService } from '../../core/school.service';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-school-app-register-student',
  templateUrl: './school-app-register-student.component.html',
  styleUrls: ['./school-app-register-student.component.css']
})
export class SchoolAppRegisterStudentComponent implements OnInit {
  @ViewChild('showSeafarerModal') showSeafarerModal: ModalDirective;
  imageLink = './../../../assets/img/seafarer-temporary-registration-template.png';
  registeredSeafarers = [];
  schoolId;
  schoolName;
  seafarer = {
    schoolId: 0,
    lastName: '',
    firstName: '',
    middleName: '',
    yearDiploma: '',
    course: '',
    email: '',
    phone: '',
    password: ''
  };
  registrationTemplate = document.getElementById('registration-template');

  constructor(
    private cookieService: CookieService,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    this.schoolId = +this.cookieService.get('roleId');
    const user = JSON.parse(this.cookieService.get('user'));
    this.schoolName = user.name;
  }

  register() {
    this.generatePassword();
    this.seafarer.schoolId = this.schoolId;
    this.seafarer.phone = `63${this.seafarer.phone}`;
    this.seafarer.yearDiploma = this.seafarer.yearDiploma + '';
    this.schoolService.registerSeafarer(this.seafarer)
      .subscribe(res => {
        if (res && res.success) {
          this.registeredSeafarers.push(this.seafarer);
          this.resetInputs();
        }
      });
  }

  resetInputs() {
    this.seafarer = {
      schoolId: 0,
      lastName: '',
      firstName: '',
      middleName: '',
      yearDiploma: null,
      course: '',
      email: '',
      phone: null,
      password: ''
    };
  }

  generatePassword() {
    const lastName = _.lowerCase(this.seafarer.lastName).replace(/\s/g, '');
    const firstNameInitial = _.upperFirst(this.seafarer.firstName).slice(0, 1);
    const middleNameInitial = _.upperFirst(this.seafarer.middleName).slice(0, 1);
    const year = _.toLower(this.seafarer.yearDiploma).slice(2);
    this.seafarer.password = lastName + firstNameInitial + middleNameInitial + year;
  }

  print() {
    if (window.print) {
      window.print();
    }
  }

  showRegistrationDetails(seafarer) {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const image = new Image();

    image.onload = drawImageActualSize;
    image.src = this.imageLink;
    const schoolName = this.schoolName;

    function drawImageActualSize() {
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      ctx.drawImage(this, 0, 0);
      ctx.drawImage(this, 0, 0, this.width, this.height);

      ctx.font = 'Bold 45px Calibri';
      ctx.fillText(seafarer.email, 900, 2405, 545);
      ctx.fillText(seafarer.password, 910, 2460, 490);
      ctx.fillText(schoolName, 660, 2730, 1500);
      ctx.fill();
    }

    this.showSeafarerModal.show();
  }

}
