import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-starter-footer',
  templateUrl: './starter-footer.component.html',
  styleUrls: ['./starter-footer.component.css']
})
export class StarterFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
