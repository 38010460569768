import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../core/chat.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-school-app-student-messages',
  templateUrl: './school-app-student-messages.component.html',
  styleUrls: ['./school-app-student-messages.component.css']
})
export class SchoolAppStudentMessagesComponent implements OnInit {
  private token;
  messages = [];
  total = 0;

  constructor(
    private chatService: ChatService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');

    this.chatService.getThreads(this.token)
      .subscribe(res => {
        if (res && res.success) {
          this.messages = res.data.items;
          this.total = res.data.total;
        }
      });
  }

}
