import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactPerson'
})
export class ContactPersonPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let ret = [];
    try {
      ret = JSON.parse(value);
    } catch (e) {
      console.error(e);
    }
    return ret;
  }

}
