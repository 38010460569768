import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppInjector } from '../app-injector';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class MotherService {
  toastr;

  constructor(
    toastr
  ) {
    this.toastr = toastr;
  }

  public log(data) {
    if (data) {
      if (!data.success && data.message) {
        this.toastr.warning(data.message);
      } else if (data.message) {
        this.toastr.success(data.message);
      }
    }
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const router = AppInjector.get(Router);
      let errMsg;
      let code;
      try {
        errMsg = error.error.errors[0].context;
        code  = error.error.errors[0].code;
      } catch (e) {
        errMsg = error.statusText || 'Please try again later.';
      }

      errMsg = errMsg || error.statusText;
      this.toastr.error(`${operation} failed: ${errMsg}`);

      // if error code is 500

      if (code === 'UNAUTH') {
        const role = router.url.split('/')[1];
        this.clearCookie(role);

        router.navigate([`${role}/login`]);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private clearCookie(role) {
    const cookieService = AppInjector.get(CookieService);
    cookieService.deleteAll('/');
  }

}
