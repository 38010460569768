import { Component, OnInit } from '@angular/core';
import { MetaService } from 'ng2-meta';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manning-agency-fbshare-posts',
  templateUrl: './manning-agency-fbshare-posts.component.html',
  styleUrls: ['./manning-agency-fbshare-posts.component.css']
})
export class ManningAgencyFbsharePostsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private metaService: MetaService) { }

  ngOnInit() {
    const {
      image,
      description,
      title,
    } = this.route.snapshot.queryParams;

    this.metaService.setTitle(title);
    this.metaService.setTag('og:image', image);
    this.metaService.setTag('og:description', description);

    /**
     * 
     * IF job post:
     *  JOB FOR <position>!
     * 
     * IF status posts:
     *  POST BY <Manning_agency>
     * 
     * TODO: redirect to app
     */
  }

}
