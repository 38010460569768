import { Component, OnInit } from '@angular/core';
import { ManningAgencyService } from '../../core/manning-agency.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manning-agency-forgot-password',
  templateUrl: './manning-agency-forgot-password.component.html',
  styleUrls: ['./manning-agency-forgot-password.component.css']
})
export class ManningAgencyForgotPasswordComponent implements OnInit {
  email = '';
  sendClicked = false;

  constructor(
    private router: Router,
    private manningAgencyService: ManningAgencyService
  ) { }

  ngOnInit() {
  }

  send() {
    this.sendClicked = true;
    const data = {
      email: this.email
    };
    this.manningAgencyService.forgotPassword(data)
      .subscribe(res => {
        if (res && res.success) {
          this.router.navigate(['/manning-agency/login']);
        } else {
          this.sendClicked = false;
        }
      });
  }

  isValid() {
    return (
      this.email.length &&
      !this.sendClicked
    );
  }

}
