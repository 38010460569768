import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-manning-agency-login',
  templateUrl: './manning-agency-login.component.html',
  styleUrls: ['./manning-agency-login.component.css']
})
export class ManningAgencyLoginComponent implements OnInit {
  private returnUrl: string;
  user = {
    email: '',
    password: ''
  };


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {

    if (this.cookieService.check('token')) {
      const currentRole = this.cookieService.get('role');
      this.router.navigate([`/${currentRole}`]);
      return;
    }

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/manning-agency';
  }

  login() {
    this.authService.login('manning_agency', this.user)
      .subscribe(response => {
        if (response && response.success) {
          if (!response.data.isAccepted || response.data.isRejected) {
            return this.router.navigate(['/manning-agency/unverified']);
          }
          const {userId, manning_agencyId, token} = response.data;
          this.cookieService.set('userId', userId, null, '/');
          this.cookieService.set('roleId', manning_agencyId, null, '/');
          this.cookieService.set('token', token, null, '/');
          this.cookieService.set('user', JSON.stringify(response.data), null, '/');
          this.cookieService.set('role', 'manning-agency', null, '/');
          this.router.navigate([this.returnUrl]);
        }
      });
  }

}
