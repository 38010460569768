import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manning-agency',
  templateUrl: './manning-agency.component.html',
  styleUrls: ['./manning-agency.component.css']
})
export class ManningAgencyComponent implements OnInit {
  url: string;
  urlStrings: any[] = [];

  constructor(private router: Router) { }

  ngOnInit() {
    this.url = this.router.url;
    this.urlStrings = this.url.split('/');
  }

}
