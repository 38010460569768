import { Component, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyService } from '../../../core/manning-agency.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AdminManningAgencyComponent } from '../../../component/admin-manning-agency/admin-manning-agency.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-manning-agency-unactivated',
  templateUrl: './manning-agency-unactivated.component.html',
  styleUrls: ['./manning-agency-unactivated.component.css']
})
export class ManningAgencyUnactivatedComponent implements OnInit {
  @ViewChild(AdminManningAgencyComponent) adminManningAgency: AdminManningAgencyComponent;
  token;
  header = 'UNACTIVATED MANNING AGENCY ACCOUNTS';
  total = 0;
  status = 'unactivated';

  labels =  [
    'Manning Agency Name', 'Email Address', 'Contact No.',
    'Temporary Password'
  ];
  properties: Array<any> = [
    'name', 'email', 'mobileNumber', 'temp_password'
  ];
  actions = [
    {
      icon: 'fa-send-o',
      action: () => {}
    },
    {
      icon: 'fa-pencil',
      action: () => {}
    },
    {
      icon: 'fa-trash-o',
      action: () => {}
    }
  ];

  constructor(
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.actions[0].action = this.sendEmail.bind(this);
    this.actions[1].action = this.editManningAgency.bind(this);
    this.actions[2].action = this.deleteManningAgency.bind(this);
  }

  onSendTotal(total) {
    this.total = total;
  }

  sendEmail(manningAgency) {
    this.adminManningAgency.sendEmail(manningAgency);
  }

  sendEmailToAll() {
    this.adminManningAgency.sendEmailToAll();
  }

  editManningAgency(manningAgency) {
    const labels = _.without(this.labels, 'Temporary Password');
    const properties = _.without(this.properties, 'temp_password');

    try {
      manningAgency.mobileNumber = JSON.parse(manningAgency.mobileNumber);
    } catch (e) {}

    // TODO: validations like phone
    const props = _.map(labels, (label, idx) => ({
      label,
      property: properties[idx]
    }))

    this.adminManningAgency.editManningAgency(manningAgency, props);
  }

  deleteManningAgency(manningAgency) {
    this.adminManningAgency.showDeleteManningAgencyModal(manningAgency);
  }

}
