import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../core/auth.service';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ChangePasswordModalComponent } from '../../core/change-password-modal/change-password-modal.component';
import { SubscriptionService } from '../../core/subscription.service';

import * as moment from 'moment';
import { ManningAgencySubscriptionModalComponent } from '../manning-agency-subscription-modal/manning-agency-subscription-modal.component';
import { ChatService } from '../../core/chat.service';

@Component({
  selector: 'app-manning-agency-header',
  templateUrl: './manning-agency-header.component.html',
  styleUrls: ['./manning-agency-header.component.css']
})
export class ManningAgencyHeaderComponent implements OnInit {
  bsModalRef: BsModalRef
  activeHeader: string;
  name;
  token;
  subscription;
  expiration;
  unreadMessagesCount = 0;
  @ViewChild('nonSubscriber') nonSubscriber: ModalDirective;
  @ViewChild('premiumSubscriber') premiumSubscriber: ModalDirective;
  @ViewChild('basicSubscriber') basicSubscriber: ModalDirective;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private headerService: ManningAgencyHeaderService,
    private modalService: BsModalService,
    private subscriptionService: SubscriptionService,
    private chatService: ChatService,
    private router: Router
  ) { }

  ngOnInit() {
    const user = JSON.parse(this.cookieService.get('user'));
    this.token = this.cookieService.get('token');
    this.name = user.name;
    this.headerService.activeHeader.subscribe(header => {
      this.activeHeader = header;
    });

    this.subscriptionService.getSubscription(this.token, user.userId)
      .subscribe(res => {
        this.subscription = res.data.class;
        this.expiration = res.data.expiration;

        if (this.activeHeader === 'new-post' && !this.isSubscriber()) {
          return this.router.navigate([`/manning-agency/posts`]);
        }

        if (this.activeHeader === 'find-seaman' && !this.isPremiumSubscriber()) {
          return this.router.navigate([`/manning-agency/posts`]);
        }

        if (this.isExpired() || !this.isSubscriber()) {
          this.nonSubscriber.show();
        }

      });

    this.chatService.getUnreadMessages(this.token)
      .subscribe(res => {
        this.unreadMessagesCount = res.data.count || 0;
      });
  }

  logout() {
    const role = this.cookieService.get('role');
    this.authService.logout(role);
  }

  showChangePasswordModal() {
    const initialState = {
      token: this.token,
      role: 'manning_agency'
    };

    this.bsModalRef = this.modalService.show(ChangePasswordModalComponent, {initialState});
  }

  isExpired() {
    return moment(this.expiration).isBefore(moment())
  }

  isSubscriber() {
    return !this.isExpired() && ['BASIC', 'PREMIUM', 'TRIAL'].includes(this.subscription);
  }

  isPremiumSubscriber() {
    return !this.isExpired() && ['PREMIUM', 'TRIAL'].includes(this.subscription);
  }

  goToPremiumLink(link) {
    if (!this.isPremiumSubscriber()) {
      return this.premiumSubscriber.show();
    }

    this.router.navigate([link]);
  }

  goToSubscriberLink(link) {
    if (!this.isSubscriber()) {
      return this.premiumSubscriber.show();
    }

    this.router.navigate([link]);
  }

  showSubscription() {
    const initialState = {
      type: 'all',
    };

    this.nonSubscriber.hide();
    this.bsModalRef = this.modalService.show(ManningAgencySubscriptionModalComponent, {initialState});
  }


}
