import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from '../../core/school.service';
import { ManningAgencyService } from '../../core/manning-agency.service';
import { SeafarerAdminService } from '../../core/seafarer-admin.service';

@Component({
  selector: 'app-manning-agency-reset-password',
  templateUrl: './manning-agency-reset-password.component.html',
  styleUrls: ['./manning-agency-reset-password.component.css']
})
export class ManningAgencyResetPasswordComponent implements OnInit {
  user = {
    password: '',
    confirmPassword: ''
  };
  token;
  sendClicked = false;
  role;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private seafarerService: SeafarerAdminService,
    private schoolService: SchoolService,
    private manningAgencyService: ManningAgencyService
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    const router = this.router.url.slice(1);
    this.role = router.split('/')[0];
  }

  isValid() {
    return (
      this.user.password.length &&
      this.user.confirmPassword.length &&
      this.user.password === this.user.confirmPassword &&
      !this.sendClicked
    );
  }

  send() {
    this.sendClicked = true;
    const data = {
      password: this.user.password,
      confirmPassword: this.user.confirmPassword,
      token: this.token
    };
    if (this.role === 'manning-agency') {
      this.manningAgencyService.resetPassword(data)
      .subscribe(res => {
        if (res && res.success) {
          this.router.navigate([`/${this.role}/login`]);
        } else {
          this.sendClicked = false;
        }
      });
    }
    else if (this.role === 'school') {
      this.schoolService.resetPassword(data)
      .subscribe(res => {
        if (res && res.success) {
          this.router.navigate([`/${this.role}/login`]);
        } else {
          this.sendClicked = false;
        }
      });
    }
    else {
      this.seafarerService.resetPassword(data)
        .subscribe(res => {
          if (res && res.success) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.seamanseaker.app';
          } else {
            this.sendClicked = false;
          }
        });
    }
  }

}
