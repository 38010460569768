import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SchoolService } from '../../core/school.service';
import { ActivatedRoute } from '@angular/router';
import { SeafarerAdminService } from '../../core/seafarer-admin.service';
import { SchoolAppStudentStatusBaseComponent } from '../school-app-student-status-base/school-app-student-status-base.component';

@Component({
  selector: 'app-school-app-student-under-training',
  templateUrl: './school-app-student-under-training.component.html',
  styleUrls: ['./school-app-student-under-training.component.css']
})
export class SchoolAppStudentUnderTrainingComponent extends SchoolAppStudentStatusBaseComponent implements OnInit {
  status = 'under_training';

  constructor(
    route: ActivatedRoute,
    cookieService: CookieService,
    schoolService: SchoolService,
    seafarerAdminService: SeafarerAdminService
  ) {
    super(route, cookieService, schoolService, seafarerAdminService);
  }

}
