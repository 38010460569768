import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ManningAgencyHeaderService } from '../manning-agency-header.service';

@Component({
  selector: 'app-school-app',
  templateUrl: './school-app.component.html',
  styleUrls: ['./school-app.component.css']
})
export class SchoolAppComponent implements OnInit {
  url: string;
  urlStrings: any[];
  currrentModule: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.url = this.router.url;
    this.urlStrings = this.url.split('/');
    this.currrentModule = this.urlStrings[2];
  }

}
