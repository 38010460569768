import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SchoolService } from '../../core/school.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-seafarer-list-table',
  templateUrl: './seafarer-list-table.component.html',
  styleUrls: ['./seafarer-list-table.component.css']
})
export class SeafarerListTableComponent implements OnInit {
  private size = 5;
  pageNum = 1;
  total = 0;
  token;
  seafarers = [];
  pagination = [];
  @Output() sendTotal = new EventEmitter<any>();
  @Input() schoolId;
  @Input() status = null;
  @Input() year;
  @Input() course;
  @Input() labels = [];
  @Input() properties = [];
  @Input() link = '';
  @Input() actions = [];

  constructor(
    private schoolService: SchoolService,
    private cookieService: CookieService
  ) {
    this.token = this.cookieService.get('token');
  }

  ngOnInit() {
    this.getSeafarerByStatus();
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;

    this.getSeafarerByStatus();
  }

  getSeafarerByStatus() {
    this.schoolService.getSeafarerByStatus(
      this.schoolId,
      this.status,
      this.token,
      this.year,
      this.course,
      this.pageNum
    )
    .subscribe(this.updateSeafarerData.bind(this));
  }

  updateSeafarerData(response) {
    if (response && response.success) {
      this.seafarers = response.data.items;
      this.total = response.data.total;
      this.pagination = new Array(Math.ceil(this.total / this.size));

      this.sendTotal.emit(this.total);
    }
  }

  isArray(obj : any) {
    return Array.isArray(obj);
  }

  isFunction(fn) {
    return _.isFunction(fn);
  }

}
