import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';

@Component({
  selector: 'app-manning-agency-app',
  templateUrl: './manning-agency-app.component.html',
  styleUrls: ['./manning-agency-app.component.css'],
  providers: [ ManningAgencyHeaderService ]
})
export class ManningAgencyAppComponent implements OnInit {
  url: string;
  urlStrings: any[];
  currrentModule: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.url = this.router.url;
    this.urlStrings = this.url.split('/');
    this.currrentModule = this.urlStrings[2];
  }

}
