import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminSchoolListComponent } from '../admin-school-list/admin-school-list.component';
import { CookieService } from 'ngx-cookie-service';
import { SchoolAdminService } from '../../../core/school-admin.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admin-school-pending-list',
  templateUrl: './admin-school-pending-list.component.html',
  styleUrls: ['./admin-school-pending-list.component.css']
})
export class AdminSchoolPendingListComponent extends AdminSchoolListComponent implements OnInit {
  @ViewChild('approveModal') approveModal: ModalDirective;
  @ViewChild('rejectModal') rejectModal: ModalDirective;


  status = 'pending';
  schoolSelected = {
    name: ''
  };
  rejectedReason = '';

  constructor(
    cookieService: CookieService,
    schoolAdminService: SchoolAdminService
  ) {
    super(cookieService, schoolAdminService);
  }

  showApproveModal(school) {
    this.schoolSelected = school;
    this.approveModal.show();
  }

  showRejectModal(school) {
    this.schoolSelected = school;
    this.rejectModal.show();
  }

}
