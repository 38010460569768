import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MotherService } from './mother.service';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable()
export class AuthService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private router: Router,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  login(role, user) {
    const url = `${this.apiUrl}/${role}/login`;

    return this.http.post<any>(url, user)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Login`))
      );
  }

  logout(role) {
    this.cookieService.deleteAll('/');
    this.router.navigate([`/${role}/login`]);
  }

  changePassword(token, role, user) {
    const url = `${this.apiUrl}/${role}/change_password`;
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };

    return this.http.post<any>(url, user, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`ChangePassword`))
      );
  }

}
