import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-underconstruction-modal',
  templateUrl: './underconstruction-modal.component.html',
  styleUrls: ['./underconstruction-modal.component.css']
})
export class UnderconstructionModalComponent implements OnInit {

  constructor(
    public location: Location,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  hide() {
    this.bsModalRef.hide();
    this.location.back();
  }

}
