import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MotherService } from './mother.service';
import { Seafarer } from '../interface/seafarer';

@Injectable({
  providedIn: 'root'
})
export class SeafarerService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }


  update(token, seafarerId, seafarer: Seafarer) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/seafarer/${seafarerId}`;

    return this.http.put<any>(url, seafarer, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Updated Seafarer`))
      );
  }

  delete(token, seafarerId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/seafarer/${seafarerId}`;

    return this.http.delete<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Deleted Seafarer`))
      );
  }

}
