import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-starter-content',
  templateUrl: './starter-content.component.html',
  styleUrls: ['./starter-content.component.css']
})
export class StarterContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
