import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SchoolService } from '../../core/school.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-school-app-student-dashboard',
  templateUrl: './school-app-student-dashboard.component.html',
  styleUrls: ['./school-app-student-dashboard.component.css']
})
export class SchoolAppStudentDashboardComponent implements OnInit {
  token;
  schoolId;
  data = {
    unactivated_accounts: 0,
    total_registered: 0,
    unread_messages: 0,
    status: {
      under_training: {
        label: 'Under Training',
        data: [],
        columnNames: ['Under Training', 'Total'],
        link: '/school/student/under-training'
      },
      on_board: {
        label: 'On Board',
        data: [],
        columnNames: ['On Board', 'Total'],
        link: '/school/student/on-board'
      },
      on_vacation: {
        label: 'On Vacation',
        data: [],
        columnNames: ['On Vacation', 'Total'],
        link: '/school/student/on-vacation'
      },
      hired_landbase: {
        label: 'Hired Land Base',
        data: [],
        columnNames: ['Hired Land Base', 'Total'],
        link: '/school/student/hired-land-base'
      },
      done_processing: {
        label: 'B.S. Graduate',
        data: [],
        columnNames: ['B.S. Graduate', 'Total'],
        link: '/school/student/graduate'
      }
    }
  };
  statuses = Object.keys(this.data.status);
  option = {
    pieHole: 0.4,
    backgroundColor: '#ecf0f5',
    resize: true,
    chartArea: {
      width:'100%',
      height:'100%'
    },
    legend: {
      position: 'none'
    },
    slices: [
      {
        color: '#4695D9'
      },
      {
        color: '#E6EAEE'
      }
    ]
  };
  seafarers = [];
  years = [];

  yearSelected;
  courseSelected;
  queryParams = {
    course: void 0,
    year: void 0
  };

  constructor(
    private cookieService: CookieService,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    this.schoolId = this.cookieService.get('roleId');
    this.token = this.cookieService.get('token');

    this.getDashboard();
  }

  getDashboard() {
    this.schoolService.getDashboard(this.schoolId, this.token, this.courseSelected, this.yearSelected)
      .subscribe(response => {
        if (response && response.success) {
          this.data.total_registered = response.data.total_registered;
          this.data.unread_messages = response.data.unread_messages;
          this.data.unactivated_accounts = response.data.unactivated_accounts;
          this.years = response.data.years || [];

          this.statuses.forEach((status) => {

            this.data.status[status].data = [
              [
                this.data.status[status].label, response.data.status[status] || 0
              ],
              [
                'Total', response.data.total_registered || 0
              ]
            ];

          });
        }

        this.getSeafarerLocation();
      });
  }

  getSeafarerLocation() {
    this.schoolService.getSeafarerLocation(this.token, this.courseSelected, this.yearSelected)
      .subscribe(response => {
        if (response && response.success) {
          this.seafarers = response.data.items;
        }
      });
  }

  onChangeCourse(course) {
    this.courseSelected = course === 'undefined'
      ? void 0
      : course;

    this.queryParams.course = this.courseSelected;

    this.getDashboard();
  }

  onChangeYear(year) {
    this.yearSelected = year === 'undefined'
      ? void 0
      : year;

    this.queryParams.year = this.yearSelected;

    this.getDashboard();
  }

}
