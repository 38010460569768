import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModel } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { ToastrModule, Toast } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { GoogleChartsModule } from 'angular-google-charts';

import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { StarterComponent } from './starter/starter.component';
import { StarterHeaderComponent } from './starter/starter-header/starter-header.component';
import { StarterLeftSideComponent } from './starter/starter-left-side/starter-left-side.component';
import { StarterContentComponent } from './starter/starter-content/starter-content.component';
import { StarterFooterComponent } from './starter/starter-footer/starter-footer.component';
import { StarterControlSidebarComponent } from './starter/starter-control-sidebar/starter-control-sidebar.component';
import { SeafarersComponent } from './admin/seafarers/seafarers.component';
import { SeafarersContentComponent } from './admin/seafarers/seafarers-content/seafarers-content.component';
import { SeafarersTotalComponent } from './admin/seafarers/seafarers-total/seafarers-total.component';
import { SeafarersDetailsComponent } from './admin/seafarers/seafarers-details/seafarers-details.component';
import { SeafarersBannedComponent } from './admin/seafarers/seafarers-banned/seafarers-banned.component';
import { SeafarersTrainingComponent } from './admin/seafarers/seafarers-training/seafarers-training.component';
import { SeafarersReviewingComponent } from './admin/seafarers/seafarers-reviewing/seafarers-reviewing.component';
import { SeafarersVacationComponent } from './admin/seafarers/seafarers-vacation/seafarers-vacation.component';
import { SeafarersOnboardComponent } from './admin/seafarers/seafarers-onboard/seafarers-onboard.component';
import { SeafarersLandbaseComponent } from './admin/seafarers/seafarers-landbase/seafarers-landbase.component';
import { SeafarersOnlineComponent } from './admin/seafarers/seafarers-online/seafarers-online.component';
import { SeafarersMessagesComponent } from './admin/seafarers/seafarers-messages/seafarers-messages.component';
import { ManningAgencyComponent } from './admin/manning-agency/manning-agency.component';
import { ManningAgencyContentComponent } from './admin/manning-agency/manning-agency-content/manning-agency-content.component';
import { ManningAgencyApprovedComponent } from './admin/manning-agency/manning-agency-approved/manning-agency-approved.component';
import { ManningAgencyPendingComponent } from './admin/manning-agency/manning-agency-pending/manning-agency-pending.component';
import { ManningAgencyRejectedComponent } from './admin/manning-agency/manning-agency-rejected/manning-agency-rejected.component';
import { ManningAgencyJobsListComponent } from './admin/manning-agency/manning-agency-jobs-list/manning-agency-jobs-list.component';
import { ManningAgencyJobsAppliedComponent } from './admin/manning-agency/manning-agency-jobs-applied/manning-agency-jobs-applied.component';
import { ManningAgencySubscriptionComponent } from './admin/manning-agency/manning-agency-subscription/manning-agency-subscription.component';
import { ManningAgencyMessagesComponent } from './admin/manning-agency/manning-agency-messages/manning-agency-messages.component';
import { ManningAgencyPendingProfileComponent } from './admin/manning-agency/manning-agency-pending-profile/manning-agency-pending-profile.component';
import { ContactPersonPipe } from './core/contact-person.pipe';
import { ManningAgencyProfileComponent } from './admin/manning-agency/manning-agency-profile/manning-agency-profile.component';
import { ManningAgencyPostsComponent } from './admin/manning-agency/manning-agency-posts/manning-agency-posts.component';
import { ManningAgencyAppComponent } from './manning-agency/manning-agency-app/manning-agency-app.component';
import { ManningAgencyHeaderComponent } from './manning-agency/manning-agency-header/manning-agency-header.component';
import { ManningAgencyNewPostsComponent } from './manning-agency/manning-agency-new-posts/manning-agency-new-posts.component';
import { ManningAgencyShortlistComponent } from './manning-agency/manning-agency-shortlist/manning-agency-shortlist.component';
import { ManningAgencyAppPostsComponent } from './manning-agency/manning-agency-app-posts/manning-agency-app-posts.component';
import { ManningAgencyAppApplicantsComponent } from './manning-agency/manning-agency-app-applicants/manning-agency-app-applicants.component';
import { ManningAgencyAppNotificationsComponent } from './manning-agency/manning-agency-app-notifications/manning-agency-app-notifications.component';
import { ManningAgencyAppProfileComponent } from './manning-agency/manning-agency-app-profile/manning-agency-app-profile.component';
import { ManningAgencyAppMessagesComponent } from './manning-agency/manning-agency-app-messages/manning-agency-app-messages.component';
import { ManningAgencyAppFindSeamanComponent } from './manning-agency/manning-agency-app-find-seaman/manning-agency-app-find-seaman.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AuthService } from './core/auth.service';
import { SessionService } from './core/session.service';
import { ManningAgencyLoginComponent } from './manning-agency/manning-agency-login/manning-agency-login.component';
import { ManningAgencyRegistrationComponent } from './manning-agency/manning-agency-registration/manning-agency-registration.component';
import { ManningAgencyService } from './core/manning-agency.service';
import { ManningAgencyUnverifiedComponent } from './manning-agency/manning-agency-unverified/manning-agency-unverified.component';
import { ManningAgencyAdminService } from './core/manning-agency-admin.service';
import { ManningAgencyForgotPasswordComponent } from './manning-agency/manning-agency-forgot-password/manning-agency-forgot-password.component';
import { ManningAgencyResetPasswordComponent } from './manning-agency/manning-agency-reset-password/manning-agency-reset-password.component';
import { SeafarerAdminService } from './core/seafarer-admin.service';
import { OperatingDaysPipe } from './core/operating-days.pipe';
import { ManningAgencyHeaderService } from './manning-agency/manning-agency-header.service';
import { KeysPipe } from './core/keys.pipe';
import { UnderconstructionModalComponent } from './core/underconstruction-modal/underconstruction-modal.component';
import { ManningAgencyAppApplicantProfileComponent } from './manning-agency/manning-agency-app-applicant-profile/manning-agency-app-applicant-profile.component';
import { ChatService } from './core/chat.service';
import { DeleteModalComponent } from './core/delete-modal/delete-modal.component';
import { InviteSeafarerModalComponent } from './core/invite-seafarer-modal/invite-seafarer-modal.component';
import { ManningAgencyAppPostsDetailsComponent } from './manning-agency/manning-agency-app-posts-details/manning-agency-app-posts-details.component';
import { ChangePasswordModalComponent } from './core/change-password-modal/change-password-modal.component';
import { SeafarerViewAccessService } from './core/seafarer-view-access.service';
import { ManningAgencyThankYouComponent } from './manning-agency/manning-agency-thank-you/manning-agency-thank-you.component';
import { SubscriptionService } from './core/subscription.service';

import { MetaConfig, MetaModule, MetaService } from 'ng2-meta';
import { ManningAgencyFbsharePostsComponent } from './manning-agency-fbshare-posts/manning-agency-fbshare-posts.component';
import { ManningAgencySubscriptionModalComponent } from './manning-agency/manning-agency-subscription-modal/manning-agency-subscription-modal.component';
import { CallbackPipe } from './callback.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { ManningAgencyChatMessagesComponent } from './admin/manning-agency/manning-agency-chat-messages/manning-agency-chat-messages.component';
import { SchoolAppLoginComponent } from './school/school-app-login/school-app-login.component';
import { SchoolAppComponent } from './school/school-app/school-app.component';
import { SchoolAppRegistrationComponent } from './school/school-app-registration/school-app-registration.component';
import { SchoolService } from './core/school.service';
import { SchoolAppStudentDashboardComponent } from './school/school-app-student-dashboard/school-app-student-dashboard.component';
import { SchoolAppLeftSideComponent } from './school/school-app-left-side/school-app-left-side.component';
import { SchoolAppAlumniDashboardComponent } from './school/school-app-alumni-dashboard/school-app-alumni-dashboard.component';
import { SchoolAppStudentMessagesComponent } from './school/school-app-student-messages/school-app-student-messages.component';
import { SchoolAppStudentComponent } from './school/school-app-student/school-app-student.component';
import { SchoolAppStudentChatComponent } from './school/school-app-student-chat/school-app-student-chat.component';
import { SchoolAppStudentUnderTrainingComponent } from './school/school-app-student-under-training/school-app-student-under-training.component';
import { SchoolAppStudentOnBoardComponent } from './school/school-app-student-on-board/school-app-student-on-board.component';
import { SchoolAppStudentOnVacationComponent } from './school/school-app-student-on-vacation/school-app-student-on-vacation.component';
import { SchoolAppStudentHiredLandBaseComponent } from './school/school-app-student-hired-land-base/school-app-student-hired-land-base.component';
import { SchoolAppStudentGraduateComponent } from './school/school-app-student-graduate/school-app-student-graduate.component';
import { SchoolAppNavbarComponent } from './school/school-app-navbar/school-app-navbar.component';
import { SchoolAppUnverifiedComponent } from './school/school-app-unverified/school-app-unverified.component';
import { SchoolAppTotalRegisteredComponent } from './school/school-app-total-registered/school-app-total-registered.component';
import { SchoolAppStudentStatusBaseComponent } from './school/school-app-student-status-base/school-app-student-status-base.component';
import { AdminSchoolDashboardComponent } from './admin/school/admin-school-dashboard/admin-school-dashboard.component';
import { AdminSchoolComponent } from './admin/school/admin-school.component';
import { SchoolAdminService } from './core/school-admin.service';
import { AdminSchoolListComponent } from './admin/school/admin-school-list/admin-school-list.component';
import { AdminSchoolPendingListComponent } from './admin/school/admin-school-pending-list/admin-school-pending-list.component';
import { AdminSchoolApprovedListComponent } from './admin/school/admin-school-approved-list/admin-school-approved-list.component';
import { AdminSchoolRejectedListComponent } from './admin/school/admin-school-rejected-list/admin-school-rejected-list.component';
import { ChatComponent } from './component/chat/chat.component';
import { SchoolProfileComponent } from './admin/school/school-profile/school-profile.component';
import { PictureComponent } from './component/picture/picture.component';
import { SeafarersLocationMapComponent } from './component/seafarers-location-map/seafarers-location-map.component';
import { SchoolAppUnregisteredStudentsComponent } from './school/school-app-unregistered-students/school-app-unregistered-students.component';
import { SeafarerListTableComponent } from './component/seafarer-list-table/seafarer-list-table.component';
import { ContentHeaderComponent } from './component/content-header/content-header.component';
import { SchoolAppRegisterStudentComponent } from './school/school-app-register-student/school-app-register-student.component';
import { CommonModalComponent } from './component/common-modal/common-modal.component';
import { SeafarersEmergencyComponent } from './admin/seafarers/seafarers-emergency/seafarers-emergency.component';
import { SchoolStudentComponent } from './component/school-student/school-student.component';
import { SeafarerService } from './core/seafarer.service';
import { setAppInjector } from './app-injector';
import { ManningAgencyUnactivatedComponent } from './admin/manning-agency/manning-agency-unactivated/manning-agency-unactivated.component';
import { ManningAgencyListTableComponent } from './component/manning-agency-list-table/manning-agency-list-table.component';
import { ArrayJoinPipe } from './core/array-join.pipe';
import { AdminManningAgencyComponent } from './component/admin-manning-agency/admin-manning-agency.component';
import { ManningAgencyBaseComponent } from './component/manning-agency-base/manning-agency-base.component';
import { SchoolForgotPasswordComponent } from './school/school-forgot-password/school-forgot-password.component';
import { SeafarerDeletePageComponent } from './seafarer/seafarer-delete-page/seafarer-delete-page.component';

const metaConfig: MetaConfig = {
  //Append a title suffix such as a site name to all titles
  //Defaults to false
  warnMissingGuard: false,
  useTitleSuffix: true,
  defaults: {
    title: 'Check out SeakerPH!',
    titleSuffix: ' | Seaker',
    'og:image': 'https://s3-ap-southeast-1.amazonaws.com/seaker-ph/seaker-logo.jpg',
    'og:description': 'Check out available jobs for you!',
    'og:type': 'website',
    'fb:app_id': '1558249830985407'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    StarterComponent,
    StarterHeaderComponent,
    StarterLeftSideComponent,
    StarterContentComponent,
    StarterFooterComponent,
    StarterControlSidebarComponent,
    SeafarersComponent,
    SeafarersContentComponent,
    SeafarersTotalComponent,
    SeafarersDetailsComponent,
    SeafarersBannedComponent,
    SeafarersTrainingComponent,
    SeafarersReviewingComponent,
    SeafarersVacationComponent,
    SeafarersOnboardComponent,
    SeafarersLandbaseComponent,
    SeafarersOnlineComponent,
    SeafarersMessagesComponent,
    ManningAgencyComponent,
    ManningAgencyContentComponent,
    ManningAgencyApprovedComponent,
    ManningAgencyPendingComponent,
    ManningAgencyRejectedComponent,
    ManningAgencyJobsListComponent,
    ManningAgencyJobsAppliedComponent,
    ManningAgencySubscriptionComponent,
    ManningAgencyMessagesComponent,
    ManningAgencyPendingProfileComponent,
    ContactPersonPipe,
    ManningAgencyProfileComponent,
    ManningAgencyPostsComponent,
    ManningAgencyAppComponent,
    ManningAgencyHeaderComponent,
    ManningAgencyNewPostsComponent,
    ManningAgencyShortlistComponent,
    ManningAgencyAppPostsComponent,
    ManningAgencyAppApplicantsComponent,
    ManningAgencyAppNotificationsComponent,
    ManningAgencyAppProfileComponent,
    ManningAgencyAppMessagesComponent,
    ManningAgencyAppFindSeamanComponent,
    AdminLoginComponent,
    ManningAgencyLoginComponent,
    ManningAgencyRegistrationComponent,
    ManningAgencyUnverifiedComponent,
    ManningAgencyForgotPasswordComponent,
    ManningAgencyResetPasswordComponent,
    OperatingDaysPipe,
    KeysPipe,
    UnderconstructionModalComponent,
    ManningAgencyAppApplicantProfileComponent,
    DeleteModalComponent,
    InviteSeafarerModalComponent,
    ManningAgencyAppPostsDetailsComponent,
    ChangePasswordModalComponent,
    ManningAgencyThankYouComponent,
    ManningAgencyFbsharePostsComponent,
    ManningAgencySubscriptionModalComponent,
    CallbackPipe,
    DateAgoPipe,
    ManningAgencyChatMessagesComponent,
    SchoolAppLoginComponent,
    SchoolAppComponent,
    SchoolAppRegistrationComponent,
    SchoolAppStudentDashboardComponent,
    SchoolAppLeftSideComponent,
    SchoolAppAlumniDashboardComponent,
    SchoolAppStudentMessagesComponent,
    SchoolAppStudentComponent,
    SchoolAppStudentChatComponent,
    SchoolAppStudentUnderTrainingComponent,
    SchoolAppStudentOnBoardComponent,
    SchoolAppStudentOnVacationComponent,
    SchoolAppStudentHiredLandBaseComponent,
    SchoolAppStudentGraduateComponent,
    SchoolAppNavbarComponent,
    SchoolAppUnverifiedComponent,
    SchoolAppTotalRegisteredComponent,
    SchoolAppStudentStatusBaseComponent,
    AdminSchoolDashboardComponent,
    AdminSchoolComponent,
    AdminSchoolListComponent,
    AdminSchoolPendingListComponent,
    AdminSchoolApprovedListComponent,
    AdminSchoolRejectedListComponent,
    ChatComponent,
    SchoolProfileComponent,
    PictureComponent,
    SeafarersLocationMapComponent,
    SchoolAppUnregisteredStudentsComponent,
    SeafarerListTableComponent,
    ContentHeaderComponent,
    SchoolAppRegisterStudentComponent,
    CommonModalComponent,
    SeafarersEmergencyComponent,
    SchoolStudentComponent,
    ManningAgencyUnactivatedComponent,
    ManningAgencyListTableComponent,
    ArrayJoinPipe,
    AdminManningAgencyComponent,
    ManningAgencyBaseComponent,
    SchoolForgotPasswordComponent,
    SeafarerDeletePageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    AccordionModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCCJ839hBwp46kKaZ98MgrjL0pUi2Htt_k'
    }),
    MetaModule.forRoot(metaConfig),
    GoogleChartsModule.forRoot('AIzaSyCCJ839hBwp46kKaZ98MgrjL0pUi2Htt_k', 'current')
  ],
  providers: [
    BsModalRef,
    BsModalService,
    CookieService,
    AuthService,
    SessionService,
    ManningAgencyService,
    ManningAgencyAdminService,
    SchoolAdminService,
    SeafarerAdminService,
    SchoolService,
    ManningAgencyHeaderService,
    ChatService,
    SeafarerViewAccessService,
    SubscriptionService,
    MetaService,
    SeafarerService
  ],
  entryComponents: [
    UnderconstructionModalComponent,
    DeleteModalComponent,
    InviteSeafarerModalComponent,
    ChangePasswordModalComponent,
    ManningAgencySubscriptionModalComponent,
    CommonModalComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
      setAppInjector(injector);
  }
}
