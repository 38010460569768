import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../../core/manning-agency-admin.service';

@Component({
  selector: 'app-manning-agency-approved',
  templateUrl: './manning-agency-approved.component.html',
  styleUrls: ['./manning-agency-approved.component.css']
})
export class ManningAgencyApprovedComponent implements OnInit {
  private size = 5;
  token;
  manning_agencies = [];
  total = 0;
  pageNum = 1;
  pagination = [];

  constructor(
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.manningAgencyService.getMA(this.token, 'approved')
      .subscribe(response => {
        if (response && response.success) {
          this.manning_agencies = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;
    this.manningAgencyService.getMA(this.token, 'approved', this.pageNum)
      .subscribe(response => {
        if (response && response.success) {
          this.manning_agencies = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

}
