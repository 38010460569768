import { Component, OnInit, ViewChild } from '@angular/core';
import { SeafarerAdminService } from '../../../core/seafarer-admin.service';
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from 'ngx-bootstrap/modal';

import * as moment from 'moment';

@Component({
  selector: 'app-seafarers-emergency',
  templateUrl: './seafarers-emergency.component.html',
  styleUrls: ['./seafarers-emergency.component.css']
})
export class SeafarersEmergencyComponent implements OnInit {
  @ViewChild('showReportModal') showReportModal: ModalDirective;
  private size = 15;
  role: string;
  baseUrl: string;
  toastr;
  token;
  seafarers = [];
  total = 0;
  pageNum = 1;
  ranks = [];
  pagination = [];
  status = 'emergency';

  imageLink = './../../../../assets/img/seaker-emergency-report.png';

  constructor(
    private cookieService: CookieService,
    private seafarerService: SeafarerAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.role = this.cookieService.get('role');
    this.baseUrl = this.role === 'admin'
      ? '/admin/seafarers'
      : '/school/alumni';
    this.seafarerService.getSeafarer(this.token, this.status, this.pageNum, this.size)
      .subscribe(response => {
        if (response && response.success) {
          this.seafarers = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;
      this.seafarerService.getSeafarer(this.token, this.status, this.pageNum, this.size)
      .subscribe(response => {
        if (response && response.success) {
          this.seafarers = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

  update(seafarer) {
    this.seafarerService.updateEmergency(this.token, seafarer)
      .subscribe(response => {
        if (response && response.success) {
          this.seafarerService.log(response.data);
        }
      });
  }

  showReportDetails() {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const image = new Image();

    image.onload = drawImageActualSize;
    image.src = this.imageLink;

    function drawImageActualSize() {
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      ctx.drawImage(this, 0, 0);
      ctx.drawImage(this, 0, 0, this.width, this.height);

      ctx.font = 'Italic 50px Calibri';
      const date = moment().format('DD MMMM YYYY HH:mm:ss');
      ctx.fillText(`Generated ${date} LT`, 1800, 300);
      ctx.fill();
    }

    this.showReportModal.show();
  }

  downloadCsv() {
    this.seafarerService.downloadCsvEmergencyReport(this.token)
      .subscribe(response => {
        const a = document.createElement('a');
        const blob = new Blob([response.data.result], {type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = response.data.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }

  print() {
    if (window.print) {
      window.print();
    }
  }

}
