import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'arrayJoin'
})
export class ArrayJoinPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    try {
      const parsedValue = JSON.parse(value);

      if (_.isArray(parsedValue)) {
        return parsedValue.join(', ');
      }

      return value;
    } catch (e) {
      return value;
    }

  }

}
