import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ChatService } from '../../../core/chat.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manning-agency-chat-messages',
  templateUrl: './manning-agency-chat-messages.component.html',
  styleUrls: ['./manning-agency-chat-messages.component.css']
})
export class ManningAgencyChatMessagesComponent implements OnInit {
  user;
  token;
  threadId;
  openedThread = {
    threadId: 0,
    userId: 0
  };
  readThreads = [];
  unreadThreads = [];
  unfilteredReadThreads = [];
  unfilteredUnreadThreads = [];
  messages = [];
  myUserId;
  openedChat = false;
  textareaRows = 2;
  message = '';
  search = '';
  files: any = [];
  galleryFiles: any = [];
  userId;

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private chatService: ChatService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.threadId = this.route.snapshot.paramMap.get('id');
    this.myUserId = +this.cookieService.get('userId');

    this.chatService.getMessages(this.token, this.threadId)
      .subscribe(res => {
        if (res && res.success) {
          this.messages = res.data.items;
          this.messages.sort((a, b) => a.messageId - b.messageId);

          if (this.messages.length) {
            const message = this.messages[0];
            this.userId = message.user1 === this.myUserId
              ? message.user2
              : message.user1;
          }
        }
      });
  }

  createMessage(message) {

    if (!message.length && !this.files.length) {
      return;
    }

    if (message.length) {

      const thread = {
        threadId: this.threadId,
        userId: this.userId,
        message
      };

      this.chatService.createMessage(this.token, thread)
        .subscribe(res => {
          if (res && res.success) {
            this.messages.push({
              photo: this.user.companyLogo || null,
              messageId: res.data.messageId,
              userId: this.myUserId,
              content: message,
              dateCreated: new Date(),
              hasAttachment: 0
            });

            this.message = '';
          }
        });

    }

    if (this.files.length) {

      Array.prototype.forEach.call(this.files, (file, fileIdx) => {

        const thread = {
          threadId: this.openedThread.threadId,
          userId: this.openedThread.userId,
          message,
          file
        };

        this.chatService.createMessageWithAttachment(this.token, thread)
          .subscribe(res => {
            if (res && res.success) {

              this.messages.push({
                photo: this.user.companyLogo || null,
                messageId: res.data.messageId,
                userId: this.myUserId,
                content: res.data.content,
                dateCreated: new Date(),
                hasAttachment: 1,
                mimetype: res.data.mimetype,
                attachment: res.data.attachment
              });

              this.message = '';
            }
          });

      });

      this.files = [];
    }
  }

}
