import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../../core/manning-agency-admin.service';

import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-manning-agency-jobs-applied',
  templateUrl: './manning-agency-jobs-applied.component.html',
  styleUrls: ['./manning-agency-jobs-applied.component.css']
})
export class ManningAgencyJobsAppliedComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD'
  };
  private size = 10;
  token;
  jobs = [];
  total = 0;
  pageNum = 1;
  pagination = [];
  today = moment();
  filter = 'live';
  interval;
  from;
  to;
  positions = [];
  historyDateRange = [];
  countDateRange = [];

  constructor(
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.manningAgencyService.getJobsApplied(this.token)
      .subscribe(response => {
        if (response && response.success) {
          this.jobs = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });

    this.interval = setInterval((function() {
      this.today = moment(this.today.add(1, 's'));
    }).bind(this), 1000);
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;
    this.manningAgencyService.getJobsApplied(this.token, this.pageNum, this.size)
      .subscribe(response => {
        if (response && response.success) {
          this.jobs = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

  updateFilter() {
    this.pageNum = 1;
    switch (this.filter) {
      case 'live':

        this.interval = setInterval((function() {
          this.today = moment(this.today.add(1, 's'));
        }).bind(this), 1000);

        this.manningAgencyService.getJobsApplied(this.token, this.pageNum)
        .subscribe(response => {
          if (response && response.success) {
            this.jobs = response.data.items;
          }
        });

        break;
      case 'history':
        clearInterval(this.interval);

        this.manningAgencyService
          .getJobsApplied(this.token, this.pageNum, this.size,
            this.historyDateRange[0] ? moment(this.historyDateRange[0]).format('YYYY-MM-DD') : null,
            this.historyDateRange[0] ? moment(this.historyDateRange[1]).format('YYYY-MM-DD') : null)
          .subscribe(response => {
            if (response && response.success) {
              this.jobs = response.data.items;
              this.pagination = new Array(Math.ceil(response.data.total / this.size));
            }
          });

        break;
      case 'applicantCount':
        clearInterval(this.interval);

        this.manningAgencyService
          .getJobsAppliedCount(this.token,
            this.countDateRange[0] ? moment(this.countDateRange[0]).format('YYYY-MM-DD') : null,
            this.countDateRange[0] ? moment(this.countDateRange[1]).format('YYYY-MM-DD') : null)
          .subscribe(response => {
            if (response && response.success) {
              this.positions = response.data.items;
            }
          });

        break;
    }
  }

  filterDate() {

    if (this.filter === 'history') {

      this.manningAgencyService
          .getJobsApplied(this.token, this.pageNum, this.size,
            moment(this.historyDateRange[0]).format('YYYY-MM-DD'),
            moment(this.historyDateRange[1]).format('YYYY-MM-DD'))
          .subscribe(response => {
            if (response && response.success) {
              this.jobs = response.data.items;
              this.pagination = new Array(Math.ceil(response.data.total / this.size));
            }
          });

    } else {

      this.manningAgencyService
          .getJobsAppliedCount(this.token,
            moment(this.countDateRange[0]).format('YYYY-MM-DD'),
            moment(this.countDateRange[1]).format('YYYY-MM-DD'))
          .subscribe(response => {
            if (response && response.success) {
              this.positions = response.data.items;
            }
          });

    }

  }

}
