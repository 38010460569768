import { Component, OnInit } from '@angular/core';
import { SeafarerAdminService } from '../../../core/seafarer-admin.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-seafarers-total',
  templateUrl: './seafarers-total.component.html',
  styleUrls: ['./seafarers-total.component.css']
})
export class SeafarersTotalComponent implements OnInit {
  private size = 20;
  role: string;
  baseUrl: string;
  token;
  seafarers = [];
  total = 0;
  pageNum = 1;
  ranks = [];
  pagination = [];

  constructor(
    private cookieService: CookieService,
    private seafarerService: SeafarerAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.role = this.cookieService.get('role');
    this.baseUrl = this.role === 'admin'
      ? '/admin/seafarers'
      : '/school/alumni';
    this.seafarerService.getSeafarer(this.token, 'total')
      .subscribe(response => {
        if (response && response.success) {
          this.seafarers = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });

    this.seafarerService.getSeafarerRankCount(this.token)
      .subscribe(response => {
        if (response && response.success) {
          this.ranks = response.data.items;
        }
      });
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;
      this.seafarerService.getSeafarer(this.token, 'total', this.pageNum)
      .subscribe(response => {
        if (response && response.success) {
          this.seafarers = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

  getMembers(rank) {
    this.seafarerService.getSeafarerRank(this.token, rank.lastRankOn)
      .subscribe(response => {
        if (response && response.success) {
          rank.members = response.data.items;
        }
      });
  }

}
