import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent implements OnInit {
  token;
  role;
  user = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  isValid() {
    return this.user.currentPassword.length
      && this.user.newPassword.length
      && this.user.confirmPassword.length;
  }

  changePassword() {
    this.authService.changePassword(this.token, this.role, this.user)
      .subscribe(() => {
        this.bsModalRef.hide(); 
      }); 
  }

}
