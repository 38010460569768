import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MotherService } from './mother.service';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class SchoolService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  register(school) {
    const url = `${this.apiUrl}/school/${school.schoolId}`;
    return this.http.put<any>(url, school)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Register School'))
      );
  }

  uploadImage(schoolId, logo) {
    const url = `${this.apiUrl}/school/${schoolId}/upload_image`;
    const requestForm = new FormData();
    requestForm.append('school', logo, logo.name);
    return this.http.post<any>(url, requestForm)
      .pipe(
        catchError(this.handleError('Upload School Logo'))
      );
  }

  getDashboard(schoolId, token, course, year) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/school/${schoolId}/dashboard?1=1`;

    if (course) {
      url += `&course=${course}`;
    }

    if (year) {
      url += `&yearAttendedTo=${year}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetSchoolDashboard`))
      );
  }

  getSeafarerStatusHistory(token, status, format, year, course) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/school/status_history?status=${status}&format=${format}`;

    if (course) {
      url += `&course=${course}`;
    }

    if (year) {
      url += `&yearAttendedTo=${year}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`getSeafarerStatusHistory`))
      );
  }

  getSeafarerLocation(token, course, year) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/school/seafarer_location?1=1`;

    if (course) {
      url += `&course=${course}`;
    }

    if (year) {
      url += `&yearAttendedTo=${year}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`getSeafarerLocation`))
      );
  }

  resetPassword(data) {
    const url = `${this.apiUrl}/school/reset_password`;
    return this.http.post<any>(url, data)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Reset Password'))
      );
  }

  getSeafarerByStatus(schoolId, status, token, year, course, page = 1) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };

    let url = `${this.apiUrl}/seafarer`;

    url += status
      ? `/${status}`
      : '';

    url += `?schoolId=${schoolId}&page=${page}`;

    if (course) {
      url += `&course=${course}`;
    }

    if (year) {
      url += `&yearAttendedTo=${year}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`getSeafarerByStatus`))
      );

  }

  getProfile(token, schoolId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/school/${schoolId}/profile`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`Get School Profile`))
      );
  }

  registerSeafarer(seafarer) {
    const url = `${this.apiUrl}/seafarer`;

    return this.http.post<any>(url, seafarer)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Register Seafarer'))
      );
  }

  sendVerificationCode(seafarerId) {
    const url = `${this.apiUrl}/seafarer/resend_code`;
    const payload = {
      seafarerId,
      useEmail: true
    };

    return this.http.post<any>(url, payload)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Send Verification Code to Seafarer'))
      );
  }

  sendVerificationCodeToUnverified(schoolId) {
    const url = `${this.apiUrl}/seafarer/resend_code`;
    const payload = {
      schoolId,
      useEmail: true
    };

    return this.http.post<any>(url, payload)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Send Verification Code to All Unverified Seafarer'))
      );
  }

  forgotPassword(email) {
    const url = `${this.apiUrl}/school/forgot_password`;
    return this.http.post<any>(url, email)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Forgot Password'))
      );
  }

}
