import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChangePasswordModalComponent } from '../../core/change-password-modal/change-password-modal.component';


@Component({
  selector: 'app-starter-header',
  templateUrl: './starter-header.component.html',
  styleUrls: ['./starter-header.component.css']
})
export class StarterHeaderComponent implements OnInit {
  bsModalRef: BsModalRef;
  token;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: BsModalService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
  }

  logout() {
    const role = this.cookieService.get('role');
    this.authService.logout(role);
  }

  showChangePasswordModal() {
    const initialState = {
      token: this.token,
      role: 'admin'
    };

    this.bsModalRef = this.modalService.show(ChangePasswordModalComponent, {initialState});
  }

}
