import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MotherService } from './mother.service';

@Injectable()
export class SeafarerAdminService extends MotherService {
  private apiUrl = environment.apiUrl;

  constructor(
    toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient
  ) { super(toastr); }

  getDashboard(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/seafarer/admin-dashboard`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetDashboard`))
      );
  }

  getSeafarerProfile(token, seafarerId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/seafarer/${seafarerId}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetSeafarerProfile`))
      );
  }

  getSeafarer(token, status, page = 1, size = 20) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/seafarer/${status}?page=${page}&size=${size}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetSeafarer`))
      );
  }

  getSeafarerRankCount(
    token, rank = null, schoolId = null, status = null,
    year = null, course = null
  ) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    let url = `${this.apiUrl}/seafarer/rank-count?`;

    if (rank) {
      url += `rank=${rank}`;
    }

    if (schoolId) {
      url += `&schoolId=${schoolId}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (year) {
      url += `&yearAttendedTo=${year}`;
    }

    if (course) {
      url += `&course=${course}`;
    }

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetSeafarerRankCount`))
      );
  }

  getSeafarerRank(token, rank) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/seafarer/rank?rank=${rank}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`GetSeafarerRank`))
      );
  }

  resetPassword(data) {
    const url = `${this.apiUrl}/seafarer/reset_password`;
    return this.http.post<any>(url, data)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError('Reset Password'))
      );
  }

  getOnlinePageData(token, format) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/admin/get_online_data?format=${format}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`getOnlinePageData`))
      );
  }

  getSubscriptionPageData(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/admin/get_subscription_data`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`getSubscriptionPageData`))
      );
  }

  updateEmergency(token, seafarer) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/emergency/${seafarer.emergencyId}`;

    return this.http.put<any>(url, seafarer, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`UpdateEmergency`))
      );
  }

  downloadCsvEmergencyReport(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-access-token': token })
    };
    const url = `${this.apiUrl}/emergency/csv`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(res => this.log(res)),
        catchError(this.handleError<any>(`DownloadCsvEmergencyReport`))
      );
  }

}
