
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';
import { ManningAgencyService } from '../../core/manning-agency.service';
import { CookieService } from 'ngx-cookie-service';

import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import * as positions from '../../core/constants/positions.json';
import * as shiptypes from '../../core/constants/shiptype.json';
import { DeleteModalComponent } from '../../core/delete-modal/delete-modal.component';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-manning-agency-app-posts',
  templateUrl: './manning-agency-app-posts.component.html',
  styleUrls: ['./manning-agency-app-posts.component.css']
})
export class ManningAgencyAppPostsComponent implements OnInit {
  bsModalRef: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false
  };
  minDate = new Date();
  @ViewChild('editPostModal') editPostModal: ModalDirective;
  @ViewChild('editJobModal') editJobModal: ModalDirective;
  token;
  manningAgencyId;
  search;
  page = 1;
  size = 1000;
  name;
  posts = [];
  seafarers = [];
  action = '';
  currentPost = {
    maPostId: 0,
    message: ''
  };
  currentJob = {
    maJobId: 0,
    position: '',
    typeOfShip: '',
    destination: '',
    salary: '',
    salaryCurrency: '',
    isUrgent: false,
    notes: '',
    expiration: new Date()
  };
  manningAgency;
  positions;
  shiptypes;
  postId;
  postType;
  activePost = 0;
  reply = '';
  currentType = 'status';

  constructor(
    private headerService: ManningAgencyHeaderService,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer,
    private maService: ManningAgencyService,
    private modalService: BsModalService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.headerService.changeHeader('posts');
    this.positions = positions;
    this.shiptypes = shiptypes;
    this.token = this.cookieService.get('token');
    this.manningAgency = JSON.parse(this.cookieService.get('user'));
    this.manningAgencyId = this.cookieService.get('roleId');
    this.postId = this.route.snapshot.paramMap.get('id');
    this.postType = this.route.snapshot.paramMap.get('type');

    if (this.postId && this.postType) {

      const get = this.postType === 'jobs'
        ? 'getJobs' : 'getPosts';

        this.maService[get](this.token, this.manningAgencyId, this.search, this.postId, this.page, this.size)
          .subscribe(posts => {
            this.posts = posts.data.items
            .sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated))
          });

    } else {

      observableForkJoin(
        this.maService.getPosts(this.token, this.manningAgencyId, this.search, null, this.page, this.size),
        this.maService.getJobs(this.token, this.manningAgencyId, this.search, null, this.page, this.size)
      )
        .subscribe(posts => this.posts = posts[0].data.items
          .concat(posts[1].data.items)
          .sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated))
        );

    }    
  }

  getLikers(type, post, postIdx) {
    this.action = 'Likes';
    this.activePost = postIdx;
    this.currentType = type;
    if (type === 'status') {
      this.maService.getPostLikers(this.token, post.maPostId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    } else {
      this.maService.getJobLikers(this.token, post.maJobId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    }
  }

  getComments(type, post, postIdx) {
    this.action = 'Comments';
    this.activePost = postIdx;
    this.currentType = type;
    if (type === 'status') {
      this.maService.getPostComments(this.token, post.maPostId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    } else {
      this.maService.getJobComments(this.token, post.maJobId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    }
  }

  getShares(type, post, postIdx) {
    this.action = 'Shares';
    this.activePost = postIdx;
    this.currentType = type;
    if (type === 'status') {
      this.maService.getPostShares(this.token, post.maPostId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    } else {
      this.maService.getJobShares(this.token, post.maJobId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
    }
  }

  getApplicants(post, postIdx) {
    this.action = 'Applicants';
    this.activePost = postIdx;
    this.maService.getApplicants(this.token, post.maJobId, this.page, this.size)
        .subscribe(seafarers => this.seafarers = seafarers.data.items);
  }

  updatePost() {
    const payload = {
      message: this.currentPost.message
    };
    this.maService.updatePost(this.token, payload, this.currentPost.maPostId)
        .subscribe(() => {
          this.editPostModal.hide();
        });
  }

  updateJob() {

    // Update data type
    this.currentJob.isUrgent = !!this.currentJob.isUrgent;
    this.currentJob.salary = this.currentJob.salary.toString();

    this.maService.updateJob(this.token, this.currentJob)
        .subscribe(() => { 
          this.editJobModal.hide(); 
        });
  }

  showJobEditModal(post) {

    try {
      this.currentJob = post;
      this.currentJob.expiration = new Date(this.currentJob.expiration);
      // this.currentJob = JSON.parse(JSON.stringify(post));
      this.editJobModal.show();
    } catch (e) { }

  }

  showPostEditModal(post) {

    try {
      this.currentPost = post;
      // this.currentPost = JSON.parse(JSON.stringify(post));
      this.editPostModal.show();
    } catch (e) { }

  }

  delete(postId, type, postIdx) {
    const initialState = {
      id: postId,
      token: this.token,
      deleteFunction: type,
      index: postIdx,
      data: this.posts,
      service: 'maService'
    };

    this.bsModalRef = this.modalService.show(DeleteModalComponent, {initialState});

  }

  cleanUrl(url) {
    url = url.replace('watch?v=', 'embed/');
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  showReply(currentSeafarer) {
    this.reply = '';
    this.seafarers.forEach(seafarer => {
      seafarer.showReply = false;
    });

    currentSeafarer.showReply = true;
  }

  addReplyComment(seafarer) {
    const reply = {
      ...this.manningAgency,
      comment: this.reply
    };
    this.reply = '';

    if (Array.isArray(seafarer.replies)) {
      seafarer.replies.push(reply);
    } else {
      seafarer.replies = [reply];
    }

    this.maService.createReplyComment(this.token, this.currentType, seafarer.maCommentId, reply.comment)
      .subscribe();
  }

}
