import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../../core/manning-agency-admin.service';

@Component({
  selector: 'app-manning-agency-jobs-list',
  templateUrl: './manning-agency-jobs-list.component.html',
  styleUrls: ['./manning-agency-jobs-list.component.css']
})
export class ManningAgencyJobsListComponent implements OnInit {
  private size = 5;
  token;
  jobs = [];
  total = 0;
  pageNum = 1;
  pagination = [];
  sortBy = 'date';

  constructor(
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.manningAgencyService.getJobs(this.token, 1, 'date', 0)
      .subscribe(response => {
        if (response && response.success) {
          this.jobs = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;
    this.manningAgencyService.getJobs(this.token, this.pageNum, this.sortBy, 0)
      .subscribe(response => {
        if (response && response.success) {
          this.jobs = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

  updateSort() {
    this.manningAgencyService.getJobs(this.token, this.pageNum, this.sortBy, 0)
      .subscribe(response => {
        if (response && response.success) {
          this.jobs = response.data.items;
          this.total = response.data.total;
          this.pagination = new Array(Math.ceil(this.total / this.size));
        }
      });
  }

}
