import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SeafarerAdminService } from '../../../core/seafarer-admin.service';

@Component({
  selector: 'app-seafarers-content',
  templateUrl: './seafarers-content.component.html',
  styleUrls: ['./seafarers-content.component.css']
})
export class SeafarersContentComponent implements OnInit {
  role: string;
  baseUrl: string;
  token;
  dashboard = {
    total: 507,
    under_training: 0,
    reviewing: 0,
    onboard: 0,
    onvacation: 0,
    land_based: 0,
    banned: 0,
    unread_messages: 4,
    online: 2,
    emergency: 0,
  };

  constructor(
    private cookieService: CookieService,
    private seafarerService: SeafarerAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.role = this.cookieService.get('role');
    this.baseUrl = this.role === 'admin'
      ? '/admin/seafarers'
      : '/school/alumni';
    this.seafarerService.getDashboard(this.token)
      .subscribe(response => {
        if (response && response.success) {
          this.dashboard = response.data;
        }
      });
  }

}
