import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SchoolService } from '../../core/school.service';
import { SeafarerAdminService } from '../../core/seafarer-admin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-app-student-status-base',
  templateUrl: './school-app-student-status-base.component.html',
  styleUrls: ['./school-app-student-status-base.component.css']
})
export class SchoolAppStudentStatusBaseComponent implements OnInit {
  route;
  cookieService;
  schoolService;
  seafarerAdminService;

  private size = 5;
  format = 'YEARLY';
  pageNum = 1;
  total = 0;
  token;
  schoolId;
  seafarers = [];
  pagination = [];
  status = 'onboard';
  historyData = [];
  ranks = [];

  year;
  course;

  constructor(
    route: ActivatedRoute,
    cookieService: CookieService,
    schoolService: SchoolService,
    seafarerAdminService: SeafarerAdminService
  ) {
    this.route = route;
    this.cookieService = cookieService;
    this.schoolService = schoolService;
    this.seafarerAdminService = seafarerAdminService;
  }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.schoolId = this.cookieService.get('roleId');

    this.year = this.route.snapshot.queryParams['year'];
    this.course = this.route.snapshot.queryParams['course'];

    this.getSeafarerByStatus();
    this.getSeafarerStatusHistory();

    if (this.status !== 'onboard') {
      return;
    }

    this.seafarerAdminService.getSeafarerRankCount(
      this.token, null, this.schoolId, this.status, this.year, this.course
    )
      .subscribe(response => {
        if (response && response.success) {
          this.ranks = response.data.items.slice(0, 5);
        }
      });
  }

  updateFormat (format) {
    this.format = format;
    this.getSeafarerStatusHistory();
  }

  updatePaginate(num) {
    this.pageNum = num < 1 ? this.pageNum
      : num > this.pagination.length
      ? this.pagination.length : num;

    this.getSeafarerByStatus();
  }

  getSeafarerByStatus() {
    this.schoolService.getSeafarerByStatus(
      this.schoolId,
      this.status,
      this.token,
      this.year,
      this.course,
      this.pageNum
    )
    .subscribe(this.updateSeafarerData.bind(this));
  }

  getSeafarerStatusHistory() {
    this.schoolService.getSeafarerStatusHistory(
      this.token,
      this.status,
      this.format,
      this.year,
      this.course
    )
    .subscribe(this.updateStatusHistoryData.bind(this));
  }

  updateSeafarerData(response) {
    if (response && response.success) {
      this.seafarers = response.data.items;
      this.total = response.data.total;
      this.pagination = new Array(Math.ceil(this.total / this.size));
    }
  }

  updateStatusHistoryData(response) {
    if (response && response.success) {
      this.historyData = response.data.items;
    }
  }

}
