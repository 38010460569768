import { Component, OnInit } from '@angular/core';
import { AdminSchoolListComponent } from '../admin-school-list/admin-school-list.component';
import { CookieService } from 'ngx-cookie-service';
import { SchoolAdminService } from '../../../core/school-admin.service';

@Component({
  selector: 'app-admin-school-rejected-list',
  templateUrl: './admin-school-rejected-list.component.html',
  styleUrls: ['./admin-school-rejected-list.component.css']
})
export class AdminSchoolRejectedListComponent extends AdminSchoolListComponent implements OnInit {

  status = 'rejected';

  constructor(
    cookieService: CookieService,
    schoolAdminService: SchoolAdminService
  ) {
    super(cookieService, schoolAdminService);
  }

}
