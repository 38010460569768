import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SchoolService } from '../../core/school.service';
import { Seafarer } from '../../interface/seafarer';
import { SeafarerService } from '../../core/seafarer.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-school-student',
  templateUrl: './school-student.component.html',
  styleUrls: ['./school-student.component.css']
})
export class SchoolStudentComponent implements OnInit {
  @ViewChild('seafarerRegistrationModal') showSeafarerModal: ModalDirective;
  @ViewChild('emailSentModal') emailSentModal: ModalDirective;
  @ViewChild('editSeafarerModal') editSeafarerModal: ModalDirective;
  @ViewChild('deleteSeafarerModal') deleteSeafarerModal: ModalDirective;
  token;
  imageLink = './../../../assets/img/seafarer-temporary-registration-template.png';
  schoolName;
  message = '';
  messageDefault = 'Emails have been all re-sent';
  bsModalService;
  seafarer: Seafarer;
  properties = [];

  constructor(
    private cookieService: CookieService,
    private schoolService: SchoolService,
    private seafarerService: SeafarerService
  ) {
  }

  ngOnInit() {
    this.token = this.cookieService.get('token');
  }

  showRegistrationDetails(seafarer) {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const image = new Image();

    image.onload = drawImageActualSize;
    image.src = this.imageLink;
    const schoolName = this.schoolName;

    function drawImageActualSize() {
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      ctx.drawImage(this, 0, 0);
      ctx.drawImage(this, 0, 0, this.width, this.height);

      ctx.font = 'Bold 45px Calibri';
      ctx.fillText(`${seafarer.lastName}, ${seafarer.firstName} ${seafarer.middleName}`, 770, 861, 545);
      ctx.fillText(seafarer.email, 900, 2405, 545);
      ctx.fillText(seafarer.password || seafarer.temp_password, 910, 2460, 490);
      ctx.fillText(schoolName, 660, 2730, 1500);
      ctx.fill();
    }

    this.showSeafarerModal.show();
  }

  print() {
    if (window.print) {
      window.print();
    }
  }

  sendEmail(seafarer) {
    this.schoolService.sendVerificationCode(seafarer.seafarerId)
      .subscribe(res => {
        if (res && res.success) {
          this.message = `Email has been sent to
          ${seafarer.lastName}, ${seafarer.firstName} ${seafarer.middleName}`;
          this.emailSentModal.show();
        }
      });
  }

  sendEmailToAll(schoolId) {
    this.schoolService.sendVerificationCodeToUnverified(schoolId)
      .subscribe(res => {
        if (res && res.success) {
          this.message = this.messageDefault;
          this.emailSentModal.show();
        }
      });
  }

  editSeafarer(seafarer, properties = []) {
    this.seafarer = seafarer;
    this.properties = properties;
    this.editSeafarerModal.show();
  }

  onSubmitEdit(form) {
    const seafarer = _.clone(form.value);
    this.generatePassword();
    seafarer.password = this.seafarer.password;

    this.seafarerService.update(this.token, this.seafarer.seafarerId, seafarer)
      .subscribe(res => {
        if (res && res.success) {
          this.seafarer.temp_password = seafarer.password;
          this.editSeafarerModal.hide();
        }
      });
  }

  generatePassword() {
    const lastName = _.lowerCase(this.seafarer.lastName).replace(/\s/g, '');
    const firstNameInitial = _.upperFirst(this.seafarer.firstName).slice(0, 1);
    const middleNameInitial = _.upperFirst(this.seafarer.middleName).slice(0, 1);
    const year = _.toLower(this.seafarer.yearDiploma).slice(2);
    this.seafarer.password = lastName + firstNameInitial + middleNameInitial + year;
  }

  showDeleteSeafarerModal(seafarer) {
    this.seafarer = seafarer;

    this.deleteSeafarerModal.show();
  }

  confirmDelete() {
    this.seafarerService.delete(this.token, this.seafarer.seafarerId)
      .subscribe(res => {
        if (res && res.success) {
          window.location.reload();
        }
      });
  }

}
