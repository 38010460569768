import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-school-app-login',
  templateUrl: './school-app-login.component.html',
  styleUrls: ['./school-app-login.component.css']
})
export class SchoolAppLoginComponent implements OnInit {
  private returnUrl: string;
  user = {
    email: '',
    password: ''
  };


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    if (this.cookieService.check('token')) {
      const currentRole = this.cookieService.get('role');
      this.router.navigate([`/${currentRole}`]);
      return;
    }

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/school';
  }

  login() {
    this.authService.login('school', this.user)
      .subscribe(response => {
        if (response && response.success) {
          const {userId, schoolId, token} = response.data;
          this.cookieService.set('userId', userId, null, '/');
          this.cookieService.set('roleId', schoolId, null, '/');
          this.cookieService.set('token', token, null, '/');
          this.cookieService.set('user', JSON.stringify(response.data), null, '/');
          this.cookieService.set('role', 'school', null, '/');
          this.router.navigate([this.returnUrl]);
        }
      });
  }

}
