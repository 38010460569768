import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SchoolService } from '../../core/school.service';
import { SchoolAdminService } from '../../core/school-admin.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-school-app-registration',
  templateUrl: './school-app-registration.component.html',
  styleUrls: ['./school-app-registration.component.css']
})
export class SchoolAppRegistrationComponent implements OnInit {
  token;
  pageNum = 0;
  user = {
    schoolId: 0,
    name: '',
    address: '',
    email: '',
    telNum: [
      {
        value: ''
      }
    ],
    telNumber: '',
    mobNum: [
      {
        value: ''
      }
    ],
    mobileNumber: '',
    cp: [
      {
        name: '',
        rank: '',
        phone: ''
      }
    ],
    lat: 14.5995,
    lng: 120.9842,
    password: '',
    confirmPassword: '',
    contactPerson: ''
  };
  logo;
  logoSrc;
  locationChosen = true;
  agree = false;
  clickedRegister = false;
  schools = [];
  status = 'unregistered';
  schoolSelected = {
    schoolId: 0,
    name: ''
  };


  constructor(
    private router: Router,
    private schoolService: SchoolService,
    private cookieService: CookieService,
    private schoolAdminService: SchoolAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');

    this.schoolAdminService.getSchools(this.token, this.status, 1, 1000)
      .subscribe(response => {
        if (response && response.success) {
          this.schools = response.data.items;
        }
      });
  }

  onChangeSchool(school) {
    this.user.schoolId = school.schoolId;
    this.user.name = school.name;
    this.user.address = school.address;
  }

  addTelNum() {
    this.user.telNum.push({value: ''});
  }

  addMobNum() {
    this.user.mobNum.push({value: ''});
  }

  addContact() {
    this.user.cp.push({
      name: '',
      rank: '',
      phone: ''
    });
  }

  readyFiles(event) {
    const files: FileList = event.target.files;
    const file = files[0];
    this.logo = file;
    const reader = new FileReader();
    reader.onload = (e) => {
      this.logoSrc = { name: file.name, imgSrc: reader.result };
    };
    reader.readAsDataURL(file);
  }

  removeImage() {
    this.logoSrc = void 0;
    this.logo = void 0;
  }

  updateLocation(event) {
    this.user.lat = event.coords.lat;
    this.user.lng = event.coords.lng;
    this.locationChosen = true;
  }

  isValid() {
    return (
      this.user.schoolId &&
      this.user.name.length &&
      this.user.address.length &&
      this.locationChosen &&
      this.user.telNum.some(telNum => !!telNum.value.length) &&
      this.user.mobNum.some(mobNum => !!mobNum.value.length)
    );
  }

  isValidContact() {
    return this.user.cp
      .some(person => !!(person.name.length && person.phone.length && person.rank.length));
  }

  isValidToReg() {
    return (
      this.agree &&
      this.user.email &&
      this.user.password.length &&
      this.user.confirmPassword.length &&
      this.user.password === this.user.confirmPassword &&
      this.logo
    );
  }

  register() {
    this.clickedRegister = true;
    const mobileNum = this.user.mobNum
      .filter(mobNum => !!mobNum.value.length)
      .map(mobNum => mobNum.value)
      .join(',');
    this.user.mobileNumber = `[${mobileNum}]`;
    const teleNum = this.user.telNum
      .filter(telNum => !!telNum.value.length)
      .map(telNum => telNum.value)
      .join(',');
    this.user.telNumber = `[${teleNum}]`;
    this.user.contactPerson = JSON.stringify(this.user.cp);

    this.schoolService.register(this.user)
      .subscribe(response => {
        if (response && response.success) {
          this.schoolService.uploadImage(response.data.id, this.logo)
            .subscribe(res => this.router.navigate(['/school/unverified']));
        }
      });
  }

}
