import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ChatService } from '../../core/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  private token;
  // receiverUserId;
  private _thread;
  private user;
  message = '';
  messages = [];
  files = [];
  sessionUserId;
  galleryFiles: any = [];
  textareaRows = 2;


  constructor(
    private chatService: ChatService,
    private cookieService: CookieService
  ) {
    this.token = this.cookieService.get('token');
    this.user = JSON.parse(this.cookieService.get('user'));
    this.sessionUserId = +this.cookieService.get('userId');
  }

  ngOnInit() {
    // TODO: Retrieve thread for userId or threadId
  }

  get thread(): any {
    return this._thread;
  }

  @Input()
  set thread(val) {
    this._thread = val;

    if (!val) {
      return;
    }

    this.getMessages(val);
  }

  getMessages(thread) {

    this.chatService.getMessages(this.token, thread.threadId)
      .subscribe(res => {
        if (res && res.success) {
          this.messages = res.data.items;
          this.messages.sort((a, b) => a.messageId - b.messageId);
        }
      });

  }

  createMessage(message) {

    if (!message.length && !this.files.length) {
      return;
    }

    if (message.length) {

      const thread = {
        threadId: this._thread.threadId,
        userId: this._thread.userId,
        message
      };

      this.chatService.createMessage(this.token, thread)
        .subscribe(res => {
          if (res && res.success) {
            this.messages.push({
              photo: this.user.companyLogo || null,
              messageId: res.data.messageId,
              userId: this.sessionUserId,
              content: message,
              dateCreated: new Date(),
              hasAttachment: 0
            });

            this.message = '';
          }
        });

    }

    if (this.files.length) {

      Array.prototype.forEach.call(this.files, (file, fileIdx) => {

        const thread = {
          threadId: this._thread.threadId,
          userId: this._thread.userId,
          message,
          file
        };

        this.chatService.createMessageWithAttachment(this.token, thread)
          .subscribe(res => {
            if (res && res.success) {

              this.messages.push({
                photo: this.user.companyLogo || null,
                messageId: res.data.messageId,
                userId: this.sessionUserId,
                content: res.data.content,
                dateCreated: new Date(),
                hasAttachment: 1,
                mimetype: res.data.mimetype,
                attachment: res.data.attachment
              });

              this.message = '';
            }
          });

      });

      this.files = [];
    }

  }

  readyFiles(event) {
    const files: FileList = event.target.files;
    this.files = [];
    this.galleryFiles = [];

    if (files.length) {
      Array.prototype.forEach.call(files, file => {
        this.files.push(file);

        if (this.isImg(file.name)) {

          const reader = new FileReader();
          reader.onload = (e) => {
            this.galleryFiles.push({ name: file.name, imgSrc: reader.result });
          };
          reader.readAsDataURL(file);

        } else {
          this.galleryFiles.push(file);
        }
      });
    }
  }

  isSender(message) {
    return message.userId === this.sessionUserId;
  }

  isImg(img: string) {
    return !!img && ['png', 'jpg', 'jpeg'].includes(img.toLocaleLowerCase().slice(img.lastIndexOf('.') + 1));
  }

  addTextRow() {
    this.textareaRows = this.textareaRows + 1;
  }

}
