import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../../core/manning-agency-admin.service';

@Component({
  selector: 'app-manning-agency-content',
  templateUrl: './manning-agency-content.component.html',
  styleUrls: ['./manning-agency-content.component.css']
})
export class ManningAgencyContentComponent implements OnInit {
  token;
  dashboard = {
    approved: 0,
    pending: 0,
    rejected: 0,
    jobs_posted: 0,
    jobs_applied: 0,
    subscription_sales: 0,
    unread_messages: 0,
    unactivated_accounts: 0
  };

  constructor(
    private cookieService: CookieService,
    private manningAgencyService: ManningAgencyAdminService
  ) { }

  ngOnInit() {
    this.token = this.cookieService.get('token');
    this.manningAgencyService.getDashboard(this.token)
      .subscribe(response => {
        if (response && response.success) {
          this.dashboard = response.data;
        }
      });
  }

}
