import { Component, OnInit, ViewChild } from '@angular/core';
import { ManningAgencyHeaderService } from '../manning-agency-header.service';
import { CookieService } from 'ngx-cookie-service';
import { ManningAgencyAdminService } from '../../core/manning-agency-admin.service';
import { ManningAgencyService } from '../../core/manning-agency.service';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UnderconstructionModalComponent } from '../../core/underconstruction-modal/underconstruction-modal.component';
import { DeleteModalComponent } from '../../core/delete-modal/delete-modal.component';
import { InviteSeafarerModalComponent } from '../../core/invite-seafarer-modal/invite-seafarer-modal.component';

@Component({
  selector: 'app-manning-agency-app-applicants',
  templateUrl: './manning-agency-app-applicants.component.html',
  styleUrls: ['./manning-agency-app-applicants.component.css']
})
export class ManningAgencyAppApplicantsComponent implements OnInit {
  @ViewChild('inviteModal') inviteModal: ModalDirective;
  bsModalRef: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false
  };
  minDate = new Date();
  maId;
  token;
  position;
  recentApplicants = {};
  ranks = [];
  seafarer = {};

  constructor(
    private headerService: ManningAgencyHeaderService,
    private cookieService: CookieService,
    private maService: ManningAgencyService,
    private manningAgencyService: ManningAgencyAdminService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit() {
    this.headerService.changeHeader('applicants');
    this.maId = this.cookieService.get('roleId');
    this.token = this.cookieService.get('token');
    this.manningAgencyService
      .getJobsAppliedCount(this.token, null, null, this.maId)
      .subscribe(response => {
        if (response && response.success) {
          this.ranks = response.data.items;
        }
      });

    this.maService.getRecentApplicants(this.token, this.maId)
      .subscribe(res => {
        if (res && res.success) {
          this.recentApplicants = res.data.items
            .reduce((prev, curr) => {
              prev[curr.dateApplied] = prev[curr.dateApplied] || [];
              prev[curr.dateApplied].push(curr);
              return prev;
            }, {});
        }
      });
  }

  getApplicants(position) {
    this.position = position;
    this.manningAgencyService
      .getJobsApplied(this.token, 1, 1000, null, null, this.maId, position)
      .subscribe(res => {
        if (res && res.success) {
          this.recentApplicants = res.data.items
            .reduce((prev, curr) => {
              prev[curr.dateApplied] = prev[curr.dateApplied] || [];
              prev[curr.dateApplied].push(curr);
              return prev;
            }, {});
        }
      });
  }

  showInviteModal(grp, idx) {

    const seafarer = this.recentApplicants[grp][idx];

    const initialState = {
      invitation: {
        maJobId: seafarer.maJobId,
        seafarerId: seafarer.seafarerId,
        maApplicantId: seafarer.maApplicantId,
        dateInvitation: null,
        timeInvitation: '',
        lookFor: ''
      },
      token: this.token,
      index: idx,
      data: this.recentApplicants[grp],
      seafarer: seafarer,
      ranks: this.ranks,
      removeData: true
    };

    this.bsModalService.show(InviteSeafarerModalComponent, {initialState});
  }

  viewPost() {
    this.bsModalService.show(UnderconstructionModalComponent);
  }

  delete(grp, idx) {

    const applicant = this.recentApplicants[grp][idx];

    const initialState = {
      id: applicant.maApplicantId,
      token: this.token,
      deleteFunction: 'unapplyApplicant',
      seafarerId: applicant.seafarerId,
      index: idx,
      data: this.recentApplicants[grp],
      message: `${applicant.firstName} ${applicant.lastName}`,
      service: 'maService',
      callback: () => {
        this.ranks.forEach(r => {
          if (r.position === applicant.position) {
            r.count--;
          }
        })
      }
    };

    this.bsModalRef = this.bsModalService.show(DeleteModalComponent, {initialState});
  }

  filterCount(ranks) {
    return ranks.filter(r => !!r.count);
  }

}
